import React from "react";
import './loading.css'
import { Spinner, Row } from "react-bootstrap";

function LoadingComponent(props) {

    return (
        <div className={"loading-component flex-column"}>
            <Row>
                <Spinner animation="border" role="status" />
            </Row>
            <Row>
                <small>Loading</small>
            </Row>
        </div>
    );
}

export default LoadingComponent;
