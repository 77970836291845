import { useAtomValue } from 'jotai';
import { loadingAtom } from '../../App-atoms';
import TrailWindNavBar from './navbar/navbar';
import SimulationOverviewPage from './dashboards/sim_overview/Sim_Overview';
import PolTrainTestCompDashboard from './dashboards/pol_train_test_comparison/dashboard';

function TrailWindApp() {

    const loading = useAtomValue(loadingAtom)

    return (
        <>
            <TrailWindNavBar />
            <PolTrainTestCompDashboard />

        </>
    );
}

export default TrailWindApp;