import { Outlet } from "react-router-dom"
import NavBar  from '../navbar/navbar'
import Login from '../login/login'
import { loggedInAtom } from "../../App-atoms";
import { useAtom } from 'jotai';



export default function Layout() {
    const [isLoggedIn, setIsLoggedIn] = useAtom(loggedInAtom)
    return (
        <>
            {isLoggedIn ?
                <>
                    <NavBar />
                    <main>
                        <Outlet />
                    </main>
                </>
                :
                <>
                    <Login />
                </>
            }

        </>

    )
}
