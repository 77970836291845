import './stop_point_vis.css';
import Plot from 'react-plotly.js'
import { minimumTimeAtom, maximumTimeAtom, filterLowerTimeAtom, filterUpperTimeAtom } from '../../apps/trailwind_app/dashboards/sim_overview/Sim_Overview-atoms';
import { useAtom, useAtomValue } from 'jotai';
import { hoverTimeAtom, hoverTimeEpsilonAtom } from '../timecontrols/timecontrols-atoms';
import { selectedAgentsAtom } from '../../App-atoms';
import { stopPointsAtom } from '../../apps/metrics_overview/Metrics_Overview-atoms';

const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

/**
 * 
 * @param {*} start start time in ms
 * @param {*} end end time in ms  
 * @param {*} length the length of each interval in ms
 * @returns 
 */
function getTimesArray(start, end, length) {
    let times = []

    while (start <= end) {
        times.push(start)
        start += length
    }
    // console.log('TIMES', times)
    return times
}


/**
 * 
 * @param {*} x is a timestamp in ms
 * @returns 
 */
function getTimeColor(x) {
    const d = new Date(x)
    let h = d.getHours() + d.getMinutes() / 60
    return (Math.sin((Math.PI / 12) * (h) - Math.PI / 2) + 1) / 2
}

function getTimeString(x) {
    const d = new Date(x)
    return DAYS[d.getDay()] + '\n' + d.toLocaleDateString('en-US') + ' ' + `${d.getHours()}:${d.getMinutes()}`
}


function StopPointsTimelineVis(props) {

    const minimumTime = useAtomValue(minimumTimeAtom);
    const maximumTime = useAtomValue(maximumTimeAtom);

    const [filterLowerTime, setFilterLowerTime] = useAtom(filterLowerTimeAtom)
    const [filterUpperTime, setFilterUpperTime] = useAtom(filterUpperTimeAtom)
    const [hoverTime, setHoverTime] = useAtom(hoverTimeAtom)
    const [hoverTimeEpsilon, setHoverTimeEpsilon] = useAtom(hoverTimeEpsilonAtom)
    const selectedAgents = useAtomValue(selectedAgentsAtom)
    const stopPoints = useAtomValue(stopPointsAtom)


    function relayout(e) {
        console.log('TIME RELAYOUT', e)
        if (Object.keys(e).includes('xaxis.range[0]')) {
            const newMin = e['xaxis.range[0]'];
            const newMax = e['xaxis.range[1]'];

            setFilterLowerTime(newMin)
            setFilterUpperTime(newMax)

            if (newMax - newMin > 6.048e+8) { // more than 7 day interval
                console.log('1 day total interval')
                setHoverTimeEpsilon(4.32e+7) // 12 hours (1 day total interval)
            }
            else if (newMax - newMin > 2.592e+8) { // more than 3 days interval (and less than 7 days)
                console.log('1 hour total interval')
                setHoverTimeEpsilon(1.8e+6) // 30 minutes (1 hour total interval)
            }
            // else if (newMax - newMin > 4.32e+7) { // more than 12 hours (and less than 3 days)
            else {
                console.log('1 minute total interval')
                setHoverTimeEpsilon(30000) // 30 seconds (1 minute total interval)
            }
            // else { // less than 12 hours
            //   console.log('instantaneous interval')
            //   setHoverTimeEpsilon(0) // instantaneous
            // }
            console.log('hover epsilon', hoverTimeEpsilon)


        }
        else {
            setFilterLowerTime(minimumTime)
            setFilterUpperTime(maximumTime)
            setHoverTimeEpsilon(4.32e+7)
        }
    }

    function hoverTimeline(e) {
        const hover_time = e.xvals[0]
        // console.log('hover')
        setHoverTime(hover_time)
    }

    function unhoverTimeline(e) {
        // console.log('unhover')
        setHoverTime(null)
    }

    // if (minimumTime == null || maximumTime == null) {
    //   return (
    //     <div className='time-controls-div'>
    //       
    //     </div>
    //   )
    // }
    // else {

    const ONE_DAY_MS = 8.64e+7
    var suggestedInterval = ONE_DAY_MS // one day
    const visibleLowerTime = Math.max(minimumTime, filterLowerTime)
    const visibleUpperTime = Math.min(maximumTime, filterUpperTime)
    if (minimumTime > Number.MIN_VALUE && maximumTime < Number.MAX_VALUE) {
        var numBuckets = 10000;
        if (visibleUpperTime - visibleLowerTime < numBuckets) {
            numBuckets = visibleUpperTime - visibleLowerTime
        }
        // console.log(visibleUpperTime - visibleLowerTime)
        suggestedInterval = (visibleUpperTime - visibleLowerTime) / (numBuckets)
        // console.log(visibleLowerTime, visibleUpperTime, Math.max(suggestedInterval, 10000))
    }

    var xValues = getTimesArray(Math.floor(visibleLowerTime), Math.ceil(visibleUpperTime), Math.floor(suggestedInterval));


    var yTickVals = []
    var yTickLabels = []

    const plotlyData = [
    ]

    const shapes = []


    for (var i = 0; i < Object.keys(selectedAgents).length; i++) {
        const uid = Object.keys(selectedAgents)[i];
        yTickVals.push(i)
        yTickLabels.push(`Agent ${uid}`)

        const agentStopPoints = stopPoints[uid]
        if (agentStopPoints) {
            agentStopPoints.map(sp => {
                // console.log(sp)
                shapes.push({
                    type: 'rect',
                    x0: sp.start_timestamp*1000,
                    x1: sp.stop_timestamp*1000,
                    y0: i-0.25,
                    y1: i+0.25,
                    line: {
                        color: '#000000',
                        width: 2
                    },
                    fillcolor: selectedAgents[uid]['color'],
                    opacity: 0.5
                })
            })
        }
    }

    if (hoverTime !== null) {
        shapes.push({
            type: 'line',
            x0: hoverTime,
            x1: hoverTime,
            y0: -0.25,
            y1: Object.keys(selectedAgents).length,
            line: {
                width: 2,
                color: '#001f3f',
                dash: 'dot'
            }
        })

        if (hoverTimeEpsilon > 0) {
            shapes.push({
                type: 'rect',
                x0: hoverTime - hoverTimeEpsilon,
                x1: hoverTime + hoverTimeEpsilon,
                y0: -0.25,
                y1: Object.keys(selectedAgents).length,
                line: {
                    color: 'rgb(60,60,60)',
                    width: 1
                },
                fillcolor: 'rgba(60,60,60,0.5)',
            })
        }
    }



    return (
        <div className='time-controls-div'>
            <div hidden={minimumTime > Number.MIN_VALUE && maximumTime < Number.MAX_VALUE} className='time-controls-mask'>
                <small> Select an agent to load the timeline.</small>
            </div>
            <Plot
                data={plotlyData}
                useResizeHandler={true}
                onRelayout={relayout}
                onHover={hoverTimeline}
                onUnhover={unhoverTimeline}
                layout={{
                    showlegend: true,
                    margin: { l: 70, r: 120, t: 5, b: 30 },
                    xaxis: { range: [visibleLowerTime, visibleUpperTime], showgrid: false, zeroline: false, visible: true, tickvals: [visibleLowerTime, visibleUpperTime], ticktext: [getTimeString(visibleLowerTime), getTimeString(visibleUpperTime)] },
                    yaxis: { range: [-0.5, Object.keys(selectedAgents).length + 0.5], fixedrange: true, tickvals: yTickVals, ticktext: yTickLabels },
                    shapes: shapes
                }}
                style={{ width: '100%', height: '100%' }}
            />
        </div>
    );
}

export default StopPointsTimelineVis;