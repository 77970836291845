import { Card, ListGroup } from 'react-bootstrap';
import './agent.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserAlt, faUserCircle, faUserMd } from '@fortawesome/free-solid-svg-icons';
import { get_trajectory_data } from '../data_util';
import { maximumTimeAtom, minimumTimeAtom, trajectoryAtom } from '../../../apps/trailwind_app/dashboards/sim_overview/Sim_Overview-atoms';
import { revisionAtom, selectedAgentsAtom } from '../../../App-atoms';
import { useAtom } from 'jotai';

function Agent(props) {

    const [trajectory, setTrajectoryAtom] = useAtom(trajectoryAtom)
    const [selectedAgents, setSelectedAgents] = useAtom(selectedAgentsAtom)
    const [minimumTimestamp, setMinimumTimestamp] = useAtom(minimumTimeAtom)
    const [maximumTimestamp, setMaximumTimestamp] = useAtom(maximumTimeAtom)
    const [rev, setRev] = useAtom(revisionAtom)

    function updateTrajData(uid) {
        get_trajectory_data(uid).then((res) => {
            // console.log(trajectory)
            trajectory[uid] = res['trajectoryData']
            if (maximumTimestamp == null || minimumTimestamp == null) {
                setMaximumTimestamp(res['maxTimestamp'], maximumTimestamp)
                setMinimumTimestamp(res['minTimestamp'], minimumTimestamp)
            }
            else {
                setMaximumTimestamp(Math.max(res['maxTimestamp'], maximumTimestamp))
                setMinimumTimestamp(Math.min(res['minTimestamp'], minimumTimestamp))
            }
            setRev(rev + 1)
        })
    }

    function onAgentClick(uid) {
        if (parseInt(props.uid) in selectedAgents) {
            // unselect agent
            delete selectedAgents[uid]
            delete trajectory[uid]
            setRev(rev + 1)
        }
        else {
            //select agent
            selectedAgents[uid] = { 'color': 'rgb(253, 128, 93)' };
            updateTrajData(uid)
        }
    }


    return (
        <ListGroup.Item className='agent' key={`agent-item-${props.uid}`} onClick={() => onAgentClick(props.uid)}>
            <span style={parseInt(props.uid) in selectedAgents ? { color: selectedAgents[parseInt(props.uid)]['color'] } : { color: 'inherit' }}>
                <FontAwesomeIcon icon={faUser} />
                &nbsp;
                Agent {props.uid}
            </span>
        </ListGroup.Item>
    );
}

export default Agent;
