import { useAtomValue } from 'jotai';
import { loadingAtom } from '../../App-atoms';
import HumonetNavBar from './navbar/navbar';
import DataFrames from './dataframes/Dataframes';

function HumoNetApp() {

    const loading = useAtomValue(loadingAtom)

    return (
        <>
            <HumonetNavBar />
            <DataFrames />

        </>
    );
}

export default HumoNetApp;