import { Col, Container, Row, ListGroup, Tab, Button } from 'react-bootstrap';
import './Dataframes.css'
import DataFrameItem from '../dataframe_item/dataframe_item';
import { useAtom, useAtomValue } from 'jotai';
import { editingDataframeAtom, viewingDataframeAtom } from '../HumoNetApp-atoms';
import DataFrameEditor from '../dataframe_editor/dataframe_editor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { tokenAtom, unameAtom } from '../../../App-atoms';
import { useEffect } from 'react';
import { delete_dataframe, get_dataframes_list } from '../../../components/common/data_util';
import { dataframeListAtom } from './Dataframes-atom';
import DataFrameViewer from '../dataframe_viewer/dataframe_viewer';

function DataFrames() {

    const [editingDataframe, setEditingDataframe] = useAtom(editingDataframeAtom)
    const [viewingDataframe, setViewingDataframe] = useAtom(viewingDataframeAtom)
    const [dataframeList, setDataframeList] = useAtom(dataframeListAtom)

    const uname = useAtomValue(unameAtom)
    const token = useAtomValue(tokenAtom)
    // /api/humonet/get_dataframe_queries

    function load_dataframes() {
        get_dataframes_list(uname, token).then(values => {
            console.log('from df.js', values)
            setDataframeList([...values.data])
            setEditingDataframe('')
            setViewingDataframe('')
        }).catch(err => {

        })
    }

    function new_dataframe() {
        let new_df = { 'name': '', query: '' }
        setDataframeList([new_df, ...dataframeList])
        setEditingDataframe(0)
        setViewingDataframe('')
    }

    useEffect(() => {
        load_dataframes()
    }, []);

    return (
        <>
            <Container className='mt-3'>
                <Row>
                    <Col>
                        <h4>Dataframes</h4>
                    </Col>
                </Row>
                <Tab.Container id="list-group-tabs-example">
                    <Row>
                        <Col className='list-of-dataframes px-0 border border-end-0 overflow-scroll'>
                            <Row className='my-3'>
                                <Col>
                                    <Button className='df-action-btn' variant='outline-secondary' size='sm' onClick={new_dataframe}>
                                        <FontAwesomeIcon icon={faPlus} /> new dataframe
                                    </Button>

                                    <Button className='df-action-btn' variant='outline-secondary' size='sm' onClick={load_dataframes}>
                                        <FontAwesomeIcon icon={faRefresh} /> refresh list
                                    </Button>
                                </Col>

                            </Row>
                            <hr />
                            <ListGroup variant='flush'>
                                {

                                    dataframeList.map(((v, i) => {
                                        var row_class = ''
                                        if (editingDataframe === i || viewingDataframe === i) {
                                            row_class = 'active-df'
                                        }

                                        return (
                                            <ListGroup.Item className={`border-bottom ${row_class}`} key={`df_list_item_${i}`}>
                                                <DataFrameItem df_index={i} df_item={v} key={`df_item_${v.name}`} />
                                            </ListGroup.Item>
                                        )
                                    }))
                                }

                            </ListGroup>



                        </Col>
                        <Col className='details-of-dataframes pr-1 border border-start-0'>
                            <Tab.Content>
                                {(editingDataframe !== '') ?
                                    <DataFrameEditor
                                        editingDataframe={editingDataframe}
                                        dataframe={dataframeList[editingDataframe]}
                                    />
                                    : <></>
                                }

                                {(viewingDataframe !== '') ?
                                    <DataFrameViewer
                                        viewingDataframe={editingDataframe}
                                        dataframe={dataframeList[viewingDataframe]}
                                    />
                                    : <></>
                                }
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
        </>
    );
}

export default DataFrames;