import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavDropdown } from 'react-bootstrap';
import './navbar.css'


function HumonetNavBar() {


    return (
        <Navbar className='py-0' bg="primary" data-bs-theme="dark">
            <Container className='mx-2 mw-100'>
                <Navbar.Brand className='user-select-none'>
                    HumoNet
                </Navbar.Brand>
                <Nav className="me-auto" >

                    <Nav.Link>Dataframes</Nav.Link>

                    <NavDropdown title="Dashboards" id="basic-nav-dropdown" disabled >
                        <NavDropdown.Item href="">Sample Dashboard</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item disabled href="">Custom Dashboard</NavDropdown.Item>
                    </NavDropdown>

                    <Nav.Link disabled>Settings</Nav.Link>

                </Nav>

            </Container>
        </Navbar>
    );
}

export default HumonetNavBar;