import './App.css';
import MetricsOverviewPage from './apps/metrics_overview/Metrics_Overview';
import { useAtomValue } from 'jotai';
import { currentPageAtom, loggedInAtom, revisionAtom } from './App-atoms';
import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { createBrowserRouter, createHashRouter, RouterProvider } from 'react-router-dom';
import Layout from './components/Layouts/Layout';
import EmptyLayout from './components/Layouts/EmptyLayout'
import Login from './components/login/login';
import { unameAtom, tokenAtom, firstNameAtom, lastNameAtom } from './App-atoms';
import PlaceholderPage from './apps/placeholder/Placeholder_Page';
import HumoNetApp from './apps/humonet_app/HumoNetApp';
import TrailWindApp from './apps/trailwind_app/TrailWindApp';


function App() {
  const currentPage = useAtomValue(currentPageAtom)
  const [loggedIn, setLoggedIn] = useAtom(loggedInAtom)
  const [uname, setUname] = useAtom(unameAtom)
  const [token, setToken] = useAtom(tokenAtom)
  const [firstName, setFirstName] = useAtom(firstNameAtom)
  const [lastName, setLastName] = useAtom(lastNameAtom)
  // const loginCheckTimer = setInterval(()=>checkLogin(uname, token), 5000)

  // async function checkLogin(un, tk) {
  //   if (!loggedIn) {
  //     clearInterval(loginCheckTimer)
  //   }
  //   return fetch('./api/check_if_active', {
  //     method: "POST",
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({
  //       uname: un,
  //       token: tk
  //     })
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {

  //       if (data['logout']) {
  //         setToken('')
  //         setUname('')
  //         setLastName('')
  //         setFirstName('')
  //         setLoggedIn(false)
  //         // alert(`Logged out due to inactivity for ${data['time_differenc']} seconds`)
  //         clearInterval(loginCheckTimer)
  //       }
        
  //       console.log( {
  //         'time_since_active': data['time_difference'],
  //         'logged_out': data['logout'],
  //       })
  //     })

  // }


  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("loggedInUser");
    console.log('CHECKING IF LOGGED IN')
    console.log(loggedInUser)
    try {
      if (loggedInUser) {
        const foundUser = JSON.parse(loggedInUser);
        setToken(foundUser.token)
        setUname(foundUser.uname)
        setLoggedIn(foundUser.loggedin)
        setFirstName(foundUser.first_name)
        setLastName(foundUser.last_name)
      }
    }
    catch (e) {
      sessionStorage.setItem('loggedInUser', null)
      // clearInterval(loginCheckTimer)
    }

  }, []);


  const router = createHashRouter([
    {
      // parent route component
      element: <Layout />,
      // child route components
      children: [
        {
          path: "/",
          element: <div></div>
        },
        {
          path: "trailwind",
          element: <TrailWindApp />
        },
        {
          path: "humonet",
          element: <HumoNetApp />,
        },
        {
          path: "trakit",
          element: <MetricsOverviewPage />,
        },
        {
          path: "presentation",
          element: <PlaceholderPage pageName={'Presentation'} />
        }
      ],
    },
    {
      element: <EmptyLayout />,
      children: [
        {
          path: 'login',
          element: <Login />
        }
      ]
    }
  ])

  return (
    <>
      <RouterProvider router={router} />
    </>

  );
}

export default App;
