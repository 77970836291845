import { Col, Row } from 'react-bootstrap';
import './dashboard.css'
// import { maximumTimeAtom, minimumTimeAtom, timeAtom, trajectoryAtom } from './Sim_Overview-atoms';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect } from 'react';
// import { revisionAtom, selectedAgentsAtom } from '../../../../App-atoms';
// import { get_trajectory_data } from '../../../../components/common/data_util';
import LoadingComponent from '../../../../components/common/loading/loading';
import { loadingAtom, selectedAgentsAtom, selectedDatasetAtom } from '../../../../App-atoms';
import POLDeckMap from './map';
import TWTimeControls from './time_selector';
import TWSidebar from '../../sidebar/sidebar';
import { polTrainAtom, polTrainLocationsAtom, polTestAtom, polTestLocationsAtom } from '../../TrailWindApp-atoms';
import POLTimelineVis from './pol_timeline';

function PolTrainTestCompDashboard() {

    const loading = useAtomValue(loadingAtom)

    const [polTrainData, setPOLTrainData] = useAtom(polTrainAtom)
    const [polTrainLocationsData, setPOLTrainLocationsData] = useAtom(polTrainLocationsAtom)
    const [polTestData, setPOLTestData] = useAtom(polTestAtom)
    const [polTestLocationsData, setPOLTestLocationsData] = useAtom(polTestLocationsAtom)

    return (
        <>
            <Row style={{ position: 'relative' }}>
                <Col className={'no-padding'} lg={2}>
                    <TWSidebar />
                </Col>
                <Col className={'no-padding'} lg={10}>
                    <Row className='m-0 p-0'>
                        <Col>
                            <div className='text-center text-uppercase bg-light'>
                                <h4 className='my-0'>
                                    Train
                                </h4>
                            </div>
                        </Col>
                        <Col>
                            <div className='text-center text-uppercase bg-light'>
                                <h4 className='my-0'>
                                    Test
                                </h4>
                            </div>
                        </Col>

                    </Row>
                    <Row style={{ 'height': '60%' }}>
                        <Col className=''>

                            <POLDeckMap
                                polData={polTrainData}
                                polLocationsData={polTrainLocationsData}
                            />
                        </Col>

                        <Col className=''>

                            <POLDeckMap
                                polData={polTestData}
                                polLocationsData={polTestLocationsData}
                            />
                        </Col>
                    </Row>
                    <Row style={{ 'height': '40%' }}>
                        <Col sm='6' className=''>
                            <TWTimeControls
                                polData={polTrainData}
                            />
                        </Col>
                        <Col sm='6'>
                            <TWTimeControls
                                polData={polTestData}
                            />
                        </Col>
                    </Row>

                </Col>
                <div className='loading' hidden={!loading}>
                    <LoadingComponent />
                </div>
            </Row>
        </>
    );
}

export default PolTrainTestCompDashboard;