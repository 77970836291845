
export async function get_list_of_agents(dataset, uname, token) {
    console.log(uname, token)

    return fetch('./api/trailwind/run_query', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            dataset: dataset,
            uname: uname,
            token: token,
            tw_endpoint: 'get_unique_agents'
        })
    })
        .then((res) => res.json())
        .then((data) => {
            const listOfAgents = Object.values(data.agent_uid)
            const numberOfAgents = Object.values(data.agent_uid).length
            console.log(data)
            return {
                'listOfAgents': listOfAgents,
                'numberOfAgents': numberOfAgents
            }
        })

}


export async function get_train_pol_data(uid, dataset, uname, token) {
    return fetch('./api/trailwind/run_query', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            uid: uid,
            dataset: dataset,
            uname: uname,
            token: token,
            tw_endpoint: 'get_train_pol'
        })
    })
        .then((res) => res.json())
        .then((data) => {
            var polData = []
            var polLocations = []
            try {
                polData = JSON.parse(data['pol'])
                polLocations = JSON.parse(data['pol_locations'])
            } catch (e) {
                console.log('Warning: PoL file does not exist')
            }
            return {
                'polData': polData,
                'polLocations': polLocations
            }
        })
}


export async function get_test_pol_data(uid, dataset, uname, token) {
    return fetch('./api/trailwind/run_query', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            uid: uid,
            dataset: dataset,
            uname: uname,
            token: token,
            tw_endpoint: 'get_test_pol'
        })
    })
        .then((res) => res.json())
        .then((data) => {
            var polData = []
            var polLocations = []
            try {
                polData = JSON.parse(data['pol'])
                polLocations = JSON.parse(data['pol_locations'])
            } catch (e) {
                console.log('Warning: PoL file does not exist')
            }
            return {
                'polData': polData,
                'polLocations': polLocations
            }
        })
}
