import { atom } from 'jotai'
import { atomWithHash } from 'jotai-location'

/**
 * list of agent UIDs
 */
export const listOfAgentsAtom = atom([])

/**
 * current page
 */
export const currentPageAtom = atom(1)

/**
 * total pages available 
 */
export const totalPagesAtom = atom(null)

/**
 * total number of agents available
 */
export const totalAgentsAtom = atom(null)


/**
 * number of agents shown per page
 */
export const agentsPerPageAtom = atom(30)
