

export async function get_list_of_datasets(uname, token) {

    return fetch('./api/get_datasets', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            uname: uname,
            token: token
        })
    })
        .then((res) => res.json())
        .then((data) => {
            return {
                'listOfDatasets': data.list_of_datasets,
                'datasetMetadata': data.metadata
            }
        })

}


export async function get_list_of_agents(dataset, uname, token) {
    console.log(uname, token)

    return fetch('./api/get_agents', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            dataset: dataset,
            uname: uname,
            token: token
        })
    })
        .then((res) => res.json())
        .then((data) => {
            const listOfAgents = Object.values(data.agent_uid)
            const numberOfAgents = Object.values(data.agent_uid).length
            console.log(data)
            return {
                'listOfAgents': listOfAgents,
                'numberOfAgents': numberOfAgents
            }
        })

}

export async function get_aoi_bound(dataset, uname, token) {
    console.log(uname, token)

    return fetch('./api/get_aoi_bound', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            dataset: dataset,
            uname: uname,
            token: token
        })
    })
        .then((res) => res.json())
        .then((data) => {
            const aoiBound = data.aoi_bound
            return {
                'aoiBound': aoiBound,
            }
        })

}


export async function get_aoi_hex8(dataset, uname, token) {
    console.log(uname, token)

    return fetch('./api/get_aoi_hex8', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            dataset: dataset,
            uname: uname,
            token: token
        })
    })
        .then((res) => res.json())
        .then((data) => {
            const aoiData = data.aoi_hex8
            return {
                'aoiHex8': aoiData,
            }
        })

}

export async function get_building_polygons(dataset, uname, token) {
    // console.log(uname, token)

    return fetch('./api/get_building_polygons', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            dataset: dataset,
            uname: uname,
            token: token
        })
    })
        .then((res) => res.json())
        .then((data) => {
            const buildingPolygons = data.building_polygons
            return {
                'buildingPolygons': buildingPolygons,
            }
        })

}

export async function get_trajectory_data(uid, dataset, uname, token) {
    // console.log('getting trajectory data')
    return fetch('./api/get_trajectory', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            uid: uid,
            dataset: dataset,
            uname: uname,
            token: token
        })
    })
        .then((res) => res.json())
        .then((data) => {
            var trajectoryData = []
            var minTimestamp = 999999999999999
            var maxTimestamp = 0
            try {
                trajectoryData = JSON.parse(data['trajectory'])
                minTimestamp = Date.parse(data['minTimestamp'])
                maxTimestamp = Date.parse(data['maxTimestamp'])
                // console.log('MIN TIME', minTimestamp)
                // console.log('MAX TIME', maxTimestamp)
            }
            catch (e) {
                // console.log(e)
                console.log('Warning: raw trajectory file does not exist')
            }
            
            return {
                'trajectoryData': trajectoryData,
                'minTimestamp': minTimestamp,
                'maxTimestamp': maxTimestamp
            }
        })
}

export async function get_pol_data(uid, dataset, uname, token) {
    return fetch('./api/get_pol', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            uid: uid,
            dataset: dataset,
            uname: uname,
            token: token
        })
    })
        .then((res) => res.json())
        .then((data) => {
            var polData = []
            var polLocations = []
            try {
                polData = JSON.parse(data['pol'])
                polLocations = JSON.parse(data['pol_locations'])
            } catch (e) {
                console.log('Warning: PoL file does not exist')
            }
            return {
                'polData': polData,
                'polLocations': polLocations
            }
        })
}

export async function get_trakit_stop_point_data(uid, dataset, uname, token) {
    return fetch('./api/get_trakit_stop_points', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            uid: uid,
            dataset: dataset,
            uname: uname,
            token: token
        })
    })
        .then((res) => res.json())
        .then((data) => {
            var stopPointData = []
            var stopPointLocations = []
            var minTimestamp = 999999999999999
            var maxTimestamp = 0
            try {
                stopPointData = JSON.parse(data['stop_points'])
                stopPointLocations = JSON.parse(data['stop_point_locations'])
                minTimestamp = parseInt(data['minTimestamp'])*1000
                maxTimestamp = parseInt(data['maxTimestamp'])*1000
            } catch (e) {
                // console.log(e)
                console.log('Warning: TraKit stop points file does not exist')
            }
            return {
                'stopPointsData': stopPointData,
                'stopPointLocations': stopPointLocations,
                'minTimestamp': minTimestamp,
                'maxTimestamp': maxTimestamp
            }
        })
}



/**
 * For humonet superuser features
 */

export async function get_dataframes_list(uname, token) {
    return fetch('./api/humonet/get_dataframe_queries', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            uname: uname,
            token: token
        })
    })
        .then((res) => res.json())
        .then((data) => {
            return data
        })
}

export async function delete_dataframe(uname, token, df_name) {
    return fetch('./api/humonet/delete_dataframe', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            uname: uname,
            token: token,
            df_name: df_name
        })
    })
        .then((res) => res.json())
        .then((data) => {
            return data
        })
}

export async function save_dataframe(uname, token, old_df_name, new_df_name, new_df_query) {
    return fetch('./api/humonet/save_dataframe', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            uname: uname,
            token: token,
            old_df_name: old_df_name,
            new_df_name: new_df_name,
            new_df_query: new_df_query
        })
    })
        .then((res) => res.json())
        .then((data) => {
            return data
        })
}

export async function run_dataframe_query(uname, token, df_name, df_query) {
    return fetch('./api/humonet/run_dataframe_query', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            uname: uname,
            token: token,
            df_name: df_name,
            df_query: df_query
        })
    })
        .then((res) => res.json())
        .then((data) => {
            return data
        })
}