import { faL } from '@fortawesome/free-solid-svg-icons'
import {atom} from 'jotai'


export const loadingAtom = atom(false)
export const currentPageAtom = atom('')
export const selectedDatasetAtom = atom('')
export const listOfDatasetsAtom = atom([])
export const datasetMetadataAtom = atom({})

/**
 * authentication stuff
 */
export const loggedInAtom = atom(false)
export const unameAtom = atom('')
export const tokenAtom = atom ('')
export const firstNameAtom = atom('')
export const lastNameAtom = atom('')

/**
 * filter and selection options
 */
export const selectedAgentsAtom = atom({})
export const mapViewAtom = atom({})


/**
 * Revision
 */
export const revisionAtom = atom(0)


/**
 * AOI atoms
 */
export const aoiBoundAtom = atom({})
export const aoiHex8Atom = atom([])
export const buildingPolygonAtom = atom([])


/**
 * Layers toggles
 */
export const showAoiBoundLayerAtom = atom(false)
export const showAoiHexLayerAtom = atom(false)
export const showBuildingPolygonsLayerAtom = atom(false)