import React, { useState } from 'react';
import { Container, Card, Button, Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMap } from '@fortawesome/free-solid-svg-icons';
import './login.css'
import { useAtom } from 'jotai';
import { loggedInAtom, tokenAtom, unameAtom, firstNameAtom, lastNameAtom } from '../../App-atoms';
import { local } from 'd3';


function Login() {

    const [loggedIn, setLoggedIn] = useAtom(loggedInAtom)
    const [uname, setUname] = useAtom(unameAtom)
    const [token, setToken] = useAtom(tokenAtom)
    const [firstName, setFirstName] = useAtom(firstNameAtom)
    const [lastName, setLastName] = useAtom(lastNameAtom)

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')



    function onLogin() {
        console.log('login')
        fetch('/api/login', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                uname: username,
                pw: password
            })
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data)
                if (data.loggedin)
                {
                    setToken(data.token)
                    setUname(data.uname)
                    setLoggedIn(data.loggedin)
                    setFirstName(data.first_name)
                    setLastName(data.last_name)

                    sessionStorage.setItem('loggedInUser', JSON.stringify(data))
                    
                    console.log('GET FROM LOCALSTORAGE', localStorage.getItem('loggedInUser'))
                }
                else{
                    alert('Unsuccessful login.')
                }
                
            })
        
        
    }

    return (
        <Container className={'d-flex justify-content-center login-container'}>
            <Card className={'bg-light login-card'}>
                <h1><FontAwesomeIcon icon={faMap} /></h1>
                <h1 className={'logo'}>TraVis</h1>

                <Form>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                        <Col sm="12">
                            <Form.Control placeholder='Username'
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                        <Col sm="12">
                            <Form.Control type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Col>
                    </Form.Group>
                </Form>


                <Button variant={'outline-dark'} onClick={onLogin}>
                    Login
                </Button>

            </Card>
        </Container>
    );
}

export default Login;