import React from 'react';
import { useState } from 'react'
import DeckGL from '@deck.gl/react';
import { DataFilterExtension, CollisionFilterExtension } from '@deck.gl/extensions'
import { Map } from 'react-map-gl';
import { TripsLayer } from '@deck.gl/geo-layers'
import { ScatterplotLayer, IconLayer, LineLayer, GeoJsonLayer, PolygonLayer } from '@deck.gl/layers'
import '../simulations_map/map.css'
import { useAtomValue } from 'jotai';
import { filterLowerTimeAtom, filterUpperTimeAtom, maximumTimeAtom, minimumTimeAtom, trajectoryAtom, polLocationsAtom, polAtom } from '../../apps/trailwind_app/dashboards/sim_overview/Sim_Overview-atoms';
import { datasetMetadataAtom, selectedAgentsAtom, selectedDatasetAtom, aoiBoundAtom, aoiHex8Atom, showAoiBoundLayerAtom, showAoiHexLayerAtom, buildingPolygonAtom, showBuildingPolygonsLayerAtom } from '../../App-atoms';
import { hoverTimeAtom, hoverTimeEpsilonAtom } from '../timecontrols/timecontrols-atoms';
import { get_collision_priority, hexToRgb } from '../../style_config';
import { stopPointsAtom, stopPointsLocationAtom } from '../../apps/metrics_overview/Metrics_Overview-atoms';
import { layer } from '@fortawesome/fontawesome-svg-core';
import { get_stop_point_icon } from '../../style_config';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

// Set your mapbox access token here
const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoibW9uYWRqZW1pIiwiYSI6ImNsb2l0Y29lZDA1ODAycHN1NzVsaWh2Z3gifQ.t_lEPP4SMc_z5Z6Vz7wB6w';



const MAP_STYLE = {
    false: 'mapbox://styles/mapbox/light-v11',
    true: 'mapbox://styles/monadjemi/clqy57h3300qe01pdb4m2e9od'
}

const ICON_MAPPING = {
    marker: { x: 0, y: 0, width: 128, height: 128, mask: true }
};

function svgToDataURL(svg) {
    return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`;
}

function getSVGIcon(life_category) {
    const faicon = get_stop_point_icon(life_category)
    var sloc_color = 'rgba(0, 0, 0, 0.5)'
    if (['home', 'work1', 'work2', 'work3'].includes(life_category)) {
        sloc_color = 'rgba(0, 0, 0, 1)'
    }
    if (faicon)
        return `<svg xmlns="http://www.w3.org/2000/svg" fill="${sloc_color}" height="160" width="180" viewBox="0 0 ${faicon.icon[0]} ${faicon.icon[1]}">
    <path d="${faicon.icon[4]}" />
    </svg>`
    else
        return `<svg xmlns="http://www.w3.org/2000/svg" height="300" width="300" viewBox="0 0 30 18">
        <text x="10" y="10">${life_category[0]}</text>
        </svg>`
}

function getAngle(x, y) {
    var angle = Math.atan2(y, x);
    var degrees = 180 * angle / Math.PI;
    return (360 + Math.round(degrees)) % 360;
}


function getIconSize(life_category) {
    if (['home', 'work1', 'work2', 'work3'].includes(life_category)) {
        return 25
    }
    else {
        return 15
    }
}

// new DataFilterExtension();

function MetricsDeckMap() {

    const trajectoryData = useAtomValue(trajectoryAtom)
    const selectedAgents = useAtomValue(selectedAgentsAtom)
    const filterLowerTime = useAtomValue(filterLowerTimeAtom)
    const filterUpperTime = useAtomValue(filterUpperTimeAtom)
    const maximumTime = useAtomValue(maximumTimeAtom)
    const minimumTime = useAtomValue(minimumTimeAtom)
    const hoverTime = useAtomValue(hoverTimeAtom)
    const hoverTimeEpsilon = useAtomValue(hoverTimeEpsilonAtom)
    const stopPointsData = useAtomValue(stopPointsAtom)
    const stopPointLocations = useAtomValue(stopPointsLocationAtom)
    const aoiBound = useAtomValue(aoiBoundAtom)
    const aoiHex8 = useAtomValue(aoiHex8Atom)
    const buildingPolygon = useAtomValue(buildingPolygonAtom)
    const showAoiBound = useAtomValue(showAoiBoundLayerAtom)
    const showAoiHex = useAtomValue(showAoiHexLayerAtom)
    const showBuildingPolygons = useAtomValue(showBuildingPolygonsLayerAtom)

    const selectedDataset = useAtomValue(selectedDatasetAtom)
    const datasetMetadata = useAtomValue(datasetMetadataAtom)


    var initialViewState = {
        longitude: -95.7129,
        latitude: 37.0902,
        zoom: 5,
        pitch: 0,
        bearing: 0
    };

    if (selectedDataset !== '') {
        const metadata = datasetMetadata[selectedDataset]
        initialViewState['latitude'] = metadata['lat_center']
        initialViewState['longitude'] = metadata['lon_center']
        initialViewState['zoom'] = 10.5
        // Viewport settings
    }



    const visibleLowerTime = Math.max(minimumTime, filterLowerTime)
    const visibleUpperTime = Math.min(maximumTime, filterUpperTime)

    let filterRange = [filterLowerTime, filterUpperTime]
    let filterRangeStopPointStartTime = [minimumTime, filterUpperTime]
    let filterRangeStopPointStopTime = [visibleLowerTime, maximumTime]

    let edgeFilterRangeStopPointStartTime = [minimumTime, filterUpperTime]
    let edgeFilterRangeStopPointStopTime = [visibleLowerTime, maximumTime]

    const [hoverInfo, setHoverInfo] = useState(null);
    const [mapStyle, setMapStyle] = useState(MAP_STYLE[false])


    const filterExt = new DataFilterExtension({ filterSize: 1 })
    const filterExt2 = new DataFilterExtension({ filterSize: 2 })




    if (hoverTime) {
        filterRange = [hoverTime - hoverTimeEpsilon, hoverTime + hoverTimeEpsilon]

        filterRangeStopPointStartTime = [minimumTime, hoverTime + hoverTimeEpsilon]
        filterRangeStopPointStopTime = [hoverTime - hoverTimeEpsilon, maximumTime]

        edgeFilterRangeStopPointStartTime = [minimumTime, hoverTime + hoverTimeEpsilon]
        edgeFilterRangeStopPointStopTime = [hoverTime - hoverTimeEpsilon, maximumTime]
    }

    const layers = [
    ];

    if (Object.keys(aoiBound).length !== 0 && showAoiBound) {
        console.log(aoiBound)
        layers.push(new GeoJsonLayer({
            id: 'AoiBoundLayer',
            data: aoiBound,
            stroked: true,
            filled: true,
            getFillColor: [160, 160, 180, 0],
            getLineColor: [0, 0, 0, 200],
            getLineWidth: 80,
        }))
    }

    if (aoiHex8.length !== 0 && showAoiHex) {
        console.log(aoiHex8)
        layers.push(new PolygonLayer({
            id: 'AoiHexLayer',
            data: aoiHex8,

            getPolygon: d => d.coordinates[0],
            // getElevation: (d: ZipCode) => d.population / d.area / 10,
            getFillColor: [100, 100, 100, 50],
            getLineColor: [90, 90, 90, 50],
            getLineWidth: 20,
            lineWidthMinPixels: 1,
            // pickable: true
        }))

    }

    if (showBuildingPolygons) {

        console.log('buildingPolygon', buildingPolygon)
        layers.push(new PolygonLayer({
            id: 'buildingPolygonLayer',
            data: buildingPolygon,
            getPolygon: d => d.polygon.coordinates[0],
            getFillColor: [100, 100, 100, 150],
            getLineColor: [90, 90, 90, 0],
            getLineWidth: 20,
            lineWidthMinPixels: 1,
        }))
    }




    function pushToLayers(uid) {
        if (trajectoryData[uid].length > 0) {
            layers.push(new TripsLayer({
                id: `trips-layer-uid-${uid}`,
                data: [{ 'waypoints': trajectoryData[uid] }],
                getPath: d => d.waypoints.map(p => [p.longitude, p.latitude]),
                getTimeStamps: d => d.waypoints.map(p => p.timestamp),
                getFilterValue: d => d.waypoints.map(p => p.timestamp),
                filterRange: filterRange,
                extensions: [filterExt],
                getColor: hexToRgb(selectedAgents[uid]['color']),
                opacity: 0.1,
                widthMinPixels: 5,
                jointRounded: true,
                capRounded: true,
                // fadeTrail: true,
                // trailLength: 200,
                // currentTime: 100
            }))

            const delta = 12
            var reducedData = [];
            for (var i = 0; i < trajectoryData[uid].length; i = i + delta) {
                reducedData.push(trajectoryData[uid][i])
            }

            layers.push(new ScatterplotLayer({
                id: `scatterplot-layer-uid-${uid}`,
                data: reducedData,
                opacity: 0.1,
                stroked: true,
                filled: true,
                radiusScale: 1,
                radiusMinPixels: 2,
                radiusMaxPixels: 5,
                lineWidthMinPixels: 0,
                getPosition: d => [d.longitude, d.latitude],
                getRadius: 15,
                getFillColor: hexToRgb(selectedAgents[uid]['color']),
                getLineColor: hexToRgb(selectedAgents[uid]['color']),
                getFilterValue: d => d.timestamp,
                filterRange: filterRange,
                extensions: [filterExt],
            })
            )
        }
        else {
            layers.push(new LineLayer({
                id: `sloc-lines-${uid}`,
                data: stopPointsData[uid],
                pickable: true,
                getWidth: 3,
                lineWidthMaxPixels: 5,
                lineWidthMinPixels: 3,
                opacity: 0.1,
                getSourcePosition: d => [d.longitude, d.latitude],
                getTargetPosition: d => [d.longitude_next, d.latitude_next],
                getColor: d => hexToRgb(selectedAgents[uid]['color']),
                getFilterValue: d => [d.stop_timestamp * 1000, d.start_timestamp_next * 1000],
                filterRange: [edgeFilterRangeStopPointStartTime, edgeFilterRangeStopPointStopTime],
                extensions: [filterExt2]
            }))

            const color = hexToRgb(selectedAgents[uid]['color'])
            const arrow_up_svg = `<svg xmlns="http://www.w3.org/2000/svg" fill="rgba(${color[0]}, ${color[1]}, ${color[2]}, 1)" height="160" width="180" viewBox="0 0 ${faCaretRight.icon[0]} ${faCaretRight.icon[1]}">
            <path d="${faCaretRight.icon[4]}" />
            </svg>`


            layers.push(new IconLayer({
                id: `sloc-arrows-${uid}`,
                data: stopPointsData[uid],
                pickable: false,
                getIcon: d => ({
                    url: `data:image/svg+xml;charset=utf-8,${encodeURIComponent(arrow_up_svg)}`,
                    width: 128,
                    height: 128,
                    anchorY: 64,
                    anchorX: 64
                }),
                sizeScale: 1,
                sizeMinPixels: 20,
                sizeMaxPixels: 30,
                getPosition: d => [(d.longitude + d.longitude_next) / 2, (d.latitude + d.latitude_next) / 2],
                getSize: d => 50,
                getFilterValue: d => [d.stop_timestamp * 1000, d.start_timestamp_next * 1000],
                getAngle: d => getAngle(d.longitude_next - d.longitude, d.latitude_next - d.latitude),
                filterRange: [edgeFilterRangeStopPointStartTime, edgeFilterRangeStopPointStopTime],
                extensions: [filterExt2]
            }))
        }

        layers.push(new ScatterplotLayer({
            id: `stop_points_${uid}`,
            data: stopPointsData[uid],
            pickable: true,
            stroked: true,
            filled: true,
            radiusScale: 8,
            radiusMinPixels: 10,
            radiusMaxPixels: 15,
            lineWidthMinPixels: 1,
            lineWidthMaxPixels: 1,
            getPosition: d => [d.longitude, d.latitude],
            getRadius: 20,
            pickable: true,
            getLineColor: 'rgba(0, 0, 0, 0)',
            opacity: 0.9,
            getFillColor: hexToRgb(selectedAgents[uid]['color']),
            onHover: info => { setHoverInfo(info) },
            onUnhover: info => { setHoverInfo(null) },
            getFilterValue: d => [d.start_timestamp * 1000, d.start_timestamp_next * 1000],
            filterRange: [filterRangeStopPointStartTime, filterRangeStopPointStopTime],
            getCollisionPriority: d => get_collision_priority(d.pol_category),
            extensions: [filterExt2, new CollisionFilterExtension()]
        })
        )

        layers.push(new IconLayer({
            id: `stop-points-icons-${uid}`,
            data: stopPointsData[uid],
            getIcon: d => ({
                url: svgToDataURL(getSVGIcon(d.pol_category)),
                width: 128,
                height: 128,
                anchorY: 64,
                anchorX: 64
            }),
            sizeScale: 1,
            sizeMinPixels: 8,
            sizeMaxPixels: 12,
            getPosition: d => [d.longitude_mean, d.latitude_mean],
            getSize: d => getIconSize(d.pol_category),
            getFilterValue: d => [d.start_timestamp * 1000, d.start_timestamp_next * 1000],
            filterRange: [filterRangeStopPointStartTime, filterRangeStopPointStopTime],
            getCollisionPriority: d => get_collision_priority(d.pol_category),
            extensions: [filterExt2, new CollisionFilterExtension()]
        }))

    }

    Object.keys(trajectoryData).forEach(function (uid) {
        if (uid in selectedAgents) {
            pushToLayers(uid)
        }
    })


    function zoomEventHandler(e) {
        if (e.interactionState.isZooming) {
            const detailedZoom = e.viewState.zoom > 19
            setMapStyle(MAP_STYLE[detailedZoom])
        }

    }

    // console.log('rendering map')
    return (
        <div className='map-div'>
            <DeckGL
                initialViewState={initialViewState}
                controller={true}
                layers={layers}
                onViewStateChange={zoomEventHandler}
                getTooltip={(object) => (hoverInfo && hoverInfo.object) && `${hoverInfo.object.pol_category}`}
            >
                <Map
                    mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
                    mapStyle={mapStyle}
                />
            </DeckGL>

        </div>

    );
}

export default MetricsDeckMap;