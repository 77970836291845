import { Col, Container, Row, ListGroup, Tab, Button, Tooltip, OverlayTrigger, Badge } from 'react-bootstrap';
import './dataframe_item.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faL, faPlay, faTable, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useAtom, useAtomValue } from 'jotai';
import { editingDataframeAtom, viewingDataframeAtom } from '../HumoNetApp-atoms';
import { dataframeListAtom, loadedDataframesDictAtom } from '../dataframes/Dataframes-atom';
import { delete_dataframe, get_dataframes_list, run_dataframe_query } from '../../../components/common/data_util';
import { tokenAtom, unameAtom } from '../../../App-atoms';
import { useEffect, useState } from 'react';

function DataFrameItem(props) {

    const uname = useAtomValue(unameAtom)
    const token = useAtomValue(tokenAtom)
    const [editingDataframe, setEditingDataframe] = useAtom(editingDataframeAtom)
    const [viewingDatafram, setViewingDataframe] = useAtom(viewingDataframeAtom)
    const [dataframeList, setDataframeList] = useAtom(dataframeListAtom)
    const [loadedDataframesDict, setLoadedDataframesDict] = useAtom(loadedDataframesDictAtom)

    const [running, setRunning] = useState(false)
    const [dataAvailable, setDataAvailable] = useState(Object.keys(loadedDataframesDict).includes(dataframeList[props.df_index].name))
    const [error, setError] = useState(false)

    useEffect(() => {
        setDataAvailable(Object.keys(loadedDataframesDict).includes(dataframeList[props.df_index].name))
    }, [loadedDataframesDict])

    function editDataframe(e) {
        setEditingDataframe(props.df_index)
        setViewingDataframe('')
    }

    function viewDataframe(e) {
        setEditingDataframe('')
        setViewingDataframe(props.df_index)
    }

    function delete_df() {
        const df_index = props.df_index
        let df = dataframeList[df_index]
        delete_dataframe(uname, token, df.name).then(values => {
            get_dataframes_list(uname, token).then(values => {
                if (Object.keys(loadedDataframesDict).includes(df.name)) {
                    delete loadedDataframesDict[df.name]
                    setLoadedDataframesDict({ ...loadedDataframesDict })
                }
                setDataframeList([...values.data])
                setEditingDataframe('')
            }).catch(err => {

            })
        })
    }

    function run_query() {
        const df_index = props.df_index
        let df = dataframeList[df_index]
        if (Object.keys(loadedDataframesDict).includes(df.name)) {
            delete loadedDataframesDict[df.name]
            setLoadedDataframesDict({ ...loadedDataframesDict })
        }
        setRunning(true)
        setError(false)
        setDataAvailable(false)
        run_dataframe_query(uname, token, df.name, df.query).then(values => {
            console.log('run_query', values)
            loadedDataframesDict[df.name] = values.data
            setLoadedDataframesDict({ ...loadedDataframesDict })
            setRunning(false)
            setDataAvailable(Object.keys(loadedDataframesDict).includes(df.name))
        }).catch(err => {
            setError(true)
            setRunning(false)
            setDataAvailable(false)
            console.log('run_query', err)
        })
    }


    return (
        <Row>
            <Col className='user-select-none'>
                <small>
                    <FontAwesomeIcon icon={faTable} /> &nbsp; {props.df_item.name}
                </small>
            </Col>

            <Col>

                {dataAvailable ?
                    <Badge className='user-select-none' pill bg="success">
                        data loaded
                    </Badge>
                    : <></>
                }

                {running ?
                    <Badge className='user-select-none' pill size='sm' bg="warning">
                        processing
                    </Badge>
                    : <></>
                }

                {error ?
                    <Badge className='user-select-none' pill bg="danger">
                        error
                    </Badge>
                    : <></>
                }


            </Col>

            <Col className='ms-auto d-flex justify-content-end'>

                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 50 }}
                    overlay={<Tooltip id={`tooltip-delete`}>
                        delete
                    </Tooltip>}
                >
                    <Button className={'df-action-btn text-secondary'} size='sm' variant='light' onClick={delete_df}>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </OverlayTrigger>


                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 50 }}
                    overlay={<Tooltip id={`tooltip-edit`}>
                        edit
                    </Tooltip>}
                >
                    <Button className={'df-action-btn text-secondary'} size='sm' variant='light' onClick={editDataframe}>
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                </OverlayTrigger>


                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 50 }}
                    overlay={<Tooltip id={`tooltip-edit`}>
                        view
                    </Tooltip>}
                >
                    <Button className={'df-action-btn text-secondary'} disabled={!dataAvailable} size='sm' variant='light' onClick={viewDataframe}>
                        <FontAwesomeIcon icon={faEye} />
                    </Button>
                </OverlayTrigger>


                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 50 }}
                    overlay={<Tooltip id={`tooltip-run`}>
                        run query
                    </Tooltip>}
                >
                    <Button className={'df-action-btn text-secondary'} size='sm' variant='light' onClick={run_query}>
                        <FontAwesomeIcon icon={faPlay} />
                    </Button>
                </OverlayTrigger>
            </Col>
        </Row>
    );
}

export default DataFrameItem;