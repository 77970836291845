import { Row, Form, InputGroup, Button, ButtonGroup, Badge, Stack } from 'react-bootstrap';
import AgentList from '../../common/AgentList/agent_list';
import './sidebar.css';
import { useAtom, useAtomValue } from 'jotai';
import { totalAgentsAtom, listOfAgentsAtom } from '../../common/AgentList/agent_list_atom';
import { datasetMetadataAtom, listOfDatasetsAtom, loadingAtom, revisionAtom, selectedDatasetAtom, tokenAtom, unameAtom, aoiBoundAtom, aoiHex8Atom, showAoiBoundLayerAtom, showAoiHexLayerAtom, showBuildingPolygonsLayerAtom, buildingPolygonAtom } from '../../../App-atoms';
import { selectedAgentsAtom } from '../../../App-atoms';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove } from '@fortawesome/free-solid-svg-icons';
import { get_trajectory_data, get_pol_data, get_list_of_agents, get_trakit_stop_point_data, get_list_of_datasets, get_aoi_bound, get_aoi_hex8, get_building_polygons } from '../../common/data_util';
import { maximumTimeAtom, minimumTimeAtom, trajectoryAtom, polAtom, polLocationsAtom, filterUpperTimeAtom, filterLowerTimeAtom } from '../../../apps/trailwind_app/dashboards/sim_overview/Sim_Overview-atoms';
import { stopPointsAtom, stopPointsLocationAtom } from '../../../apps/metrics_overview/Metrics_Overview-atoms';
import { COLORS } from '../../../style_config';

function Sidebar(props) {

  const [listOfDatasets, setListOfDatasets] = useAtom(listOfDatasetsAtom)
  const [datasetMetadata, setDatasetMetadata] = useAtom(datasetMetadataAtom)
  const [selectedDataset, setSelectedDataset] = useAtom(selectedDatasetAtom)
  const [listOfAgents, setListOfAgents] = useAtom(listOfAgentsAtom)
  const [totalAgents, setTotalAgents] = useAtom(totalAgentsAtom)
  const [selectedAgents, setSelectedAgents] = useAtom(selectedAgentsAtom)
  const [trajectoryData, setTrajectoryData] = useAtom(trajectoryAtom)
  const [polData, setPOLData] = useAtom(polAtom)
  const [polLocationsData, setPOLLocationsData] = useAtom(polLocationsAtom)
  const [minimumTimestamp, setMinimumTimestamp] = useAtom(minimumTimeAtom)
  const [maximumTimestamp, setMaximumTimestamp] = useAtom(maximumTimeAtom)
  const [filterUpperTime, setFilterUpperTime] = useAtom(filterUpperTimeAtom)
  const [filterLowerTime, setFilterLowerTime] = useAtom(filterLowerTimeAtom)

  const [aoiBound, setAoiBound] = useAtom(aoiBoundAtom)
  const [aoiHex8, setAoiHex8] = useAtom(aoiHex8Atom)
  const [buildingPolygons, setBuildingPolygons] = useAtom(buildingPolygonAtom)


  const [showAoiBound, setShowAoiBound] = useAtom(showAoiBoundLayerAtom)
  const [showAoiHex, setShowAoiHex] = useAtom(showAoiHexLayerAtom)
  const [showBuildingPolygons, setShowBuildingPolygons] = useAtom(showBuildingPolygonsLayerAtom)


  const [loading, setLoading] = useAtom(loadingAtom)
  const uname = useAtomValue(unameAtom)
  const token = useAtomValue(tokenAtom)

  const [rev, setRev] = useAtom(revisionAtom)
  const [agentInput, setAgentInput] = useState('')

  const [stopPoints, setStopPoints] = useAtom(stopPointsAtom)
  const [stopPointLocations, setStopPointLocations] = useAtom(stopPointsLocationAtom)


  useEffect(() => {
    get_list_of_datasets(uname, token).then(res => {
      setListOfDatasets(res.listOfDatasets)
      setDatasetMetadata(res.datasetMetadata)
    })
  }, []);

  function load_agents(dataset) {
    setLoading(true)
    console.log(uname, token)
    console.log('load_agents')
    Promise.all([get_list_of_agents(dataset, uname, token)]).then(res => {
      console.log(res)
      setListOfAgents(res[0].listOfAgents)
      setTotalAgents(res[0].numberOfAgents)
      setLoading(false)
    })
  }

  function load_aoi_bound(dataset) {
    Promise.all([get_aoi_bound(dataset, uname, token)]).then(res => {
      setAoiBound(JSON.parse(res[0]['aoiBound']))
    })
  }

  function load_aoi_hex8(dataset) {
    Promise.all([get_aoi_hex8(dataset, uname, token)]).then(res => {
      console.log(res[0]['aoiHex8'])
      setAoiHex8(res[0]['aoiHex8'])
    })
  }


  function load_building_polygons(dataset) {
    Promise.all([get_building_polygons(dataset, uname, token)]).then(res => {
      console.log('building polygons', res)
      setBuildingPolygons(res[0]['buildingPolygons'])
    })
  }

  function selectData(d) {
    // console.log(d, selectedDataset)

    if (d === '') {
      setSelectedDataset('')
    }
    else {
      console.log('selectData', d)
      setSelectedDataset(d)
      setMinimumTimestamp(null)
      setMaximumTimestamp(null)
      setFilterLowerTime(Number.MIN_VALUE)
      setFilterUpperTime(Number.MAX_VALUE)
      setSelectedAgents({})
      setTrajectoryData({})
      setPOLData({})
      setPOLLocationsData({})
      setAoiBound({})
      setAoiHex8([])
      setBuildingPolygons([])
      
      // console.log(d, selectedDataset)
      load_agents(d)
      load_aoi_bound(d)
      load_aoi_hex8(d)
      load_building_polygons(d)
    }
  }


  function addAgents() {
    var input = agentInput.trim()
    console.log(input)
    try {
      input = input.split(',')
      input = input.map(x => x.trim())
      for (const uid of input) {
        console.log(uid)
        validateAgent(uid)
      }
      console.log('all agents are valid')

      setLoading(true)

      const trajectoryPromises = input.map(uid => get_trajectory_data(uid, selectedDataset, uname, token))
      const polPromises = input.map(uid => get_pol_data(uid, selectedDataset, uname, token))
      const stopPointPromises = input.map(uid => get_trakit_stop_point_data(uid, selectedDataset, uname, token))

      Promise.all(trajectoryPromises.concat(polPromises).concat(stopPointPromises)).then(values => {
        var newSelectedAgent = { ...selectedAgents }
        var newTrajectoryData = { ...trajectoryData }
        var newPOLData = { ...polData }
        var newPOLLocationsData = { ...polLocationsData }
        var newStopPointData = { ...stopPoints }
        var newStopPointLocations = { ...stopPointLocations }
        var newMinTime = Number.MAX_VALUE
        var newMaxTime = Number.MIN_VALUE


        for (var i = 0; i < input.length; i++) {
          const uid = input[i]
          newSelectedAgent[uid] = { 'color': COLORS[Object.keys(newSelectedAgent).length % COLORS.length] }
          newTrajectoryData[uid] = values[i]['trajectoryData']
          newMinTime = Math.min(newMinTime, values[i]['minTimestamp'])
          newMaxTime = Math.max(newMaxTime, values[i]['maxTimestamp'])

          newPOLData[uid] = values[i + input.length]['polData']
          newPOLLocationsData[uid] = values[i + input.length]['polLocations']

          newStopPointData[uid] = values[i + 2 * input.length]['stopPointsData']
          newStopPointLocations[uid] = values[i + 2 * input.length]['stopPointLocations']
          console.log(newMinTime, values[i + 2 * input.length]['minTimestamp'])
          newMinTime = Math.min(newMinTime, values[i + 2 * input.length]['minTimestamp'])
          newMaxTime = Math.max(newMaxTime, values[i + 2 * input.length]['maxTimestamp'])
        }
        console.log(newMinTime)
        setMinimumTimestamp(newMinTime)
        setMaximumTimestamp(newMaxTime)
        setSelectedAgents(newSelectedAgent)
        setTrajectoryData(newTrajectoryData)
        setPOLData(newPOLData)
        setPOLLocationsData(newPOLLocationsData)
        setStopPoints(newStopPointData)
        setStopPointLocations(newStopPointLocations)
        setLoading(false)
      })

      setAgentInput('')
    }
    catch (e) {
      // setRev(rev + 1)
      alert(e.message)
    }
  }

  function validateAgent(uid) {
    if (uid == '') {
      throw Error('Empty UID is invalid. This may be due a trailing comma.')
    }
    else if (Object.keys(selectedAgents).includes(uid)) {
      throw Error(`Repeated UID detected. ${uid} is already selected.`)
    }
    else if (!listOfAgents.includes(parseInt(uid))) {
      console.log(listOfAgents)
      throw Error(`Agent ${uid} does not exist in the dataset.`)
    }
  }

  function addAgent(uid) {
    setLoading(true)
    var newSelectedAgents = { ...selectedAgents }
    var newMinTime = Number.MAX_VALUE
    var newMaxTime = Number.MIN_VALUE
    newSelectedAgents[uid] = { 'color': COLORS[Object.keys(newSelectedAgents).length % COLORS.length] }
    setSelectedAgents(newSelectedAgents)

    Promise.all([
      get_trajectory_data(uid, selectedDataset, uname, token),
      get_pol_data(uid, selectedDataset, uname, token),
      get_trakit_stop_point_data(uid, selectedDataset, uname, token)
    ]).then((res) => {
      // if (maximumTimestamp == null || minimumTimestamp == null) {
      //   setMaximumTimestamp(res[0]['maxTimestamp'])
      //   console.log(res[0]['maxTimestamp'], 'setting max time')
      //   setMinimumTimestamp(res[0]['minTimestamp'])
      //   console.log(res[0]['minTimestamp'], 'setting min time')

      //   setMaximumTimestamp(Math.max(res[2]['maxTimestamp'], maximumTimestamp))
      //   setMinimumTimestamp(Math.min(res[2]['minTimestamp'], minimumTimestamp))
      // }
      // else {
      //   setMaximumTimestamp(Math.max(res[0]['maxTimestamp'], res[2]['maxTimestamp'], maximumTimestamp))
      //   setMinimumTimestamp(Math.min(res[0]['minTimestamp'], res[2]['maxTimestamp'], minimumTimestamp))
      // }

      newMinTime = Math.min(newMinTime, res[0]['minTimestamp'])
      newMaxTime = Math.max(newMaxTime, res[0]['maxTimestamp'])

      newMinTime = Math.min(newMinTime, res[2]['minTimestamp'])
      newMaxTime = Math.max(newMaxTime, res[2]['maxTimestamp'])

      var newTrajectoryData = { ...trajectoryData }
      var newPOLData = { ...polData }
      var newPOLLocationsData = { ...polLocationsData }
      var newStopPointData = { ...stopPoints }
      var newStopPointLocations = { ...stopPointLocations }
      newTrajectoryData[uid] = res[0]['trajectoryData']
      newPOLData[uid] = res[1]['polData']
      newPOLLocationsData[uid] = res[1]['polLocations']
      newStopPointData[uid] = res[2]['stopPointsData']
      newStopPointLocations[uid] = res[2]['stopPointLocations']

      setTrajectoryData(newTrajectoryData)
      setPOLData(newPOLData)
      setPOLLocationsData(newPOLLocationsData)
      setStopPoints(newStopPointData)
      setStopPointLocations(newStopPointLocations)
      setLoading(false)
      setMinimumTimestamp(newMinTime)
      setMaximumTimestamp(newMaxTime)
      console.log(minimumTimestamp, maximumTimestamp, 'test')
      // console.log(newPOLLocationsData)
    })
  }

  function removeAgent(uid) {
    delete selectedAgents[uid]
    if (Object.keys(selectedAgents).length == 0) {
      setMaximumTimestamp(null)
      setMinimumTimestamp(null)
    }
    // setRev(rev + 1)
    var newSelectedAgents = { ...selectedAgents }
    setSelectedAgents(newSelectedAgents)
  }

  function addRandomAgent() {
    var randomAgentIndex = Math.floor(Math.random() * listOfAgents.length)
    var randomUID = listOfAgents[randomAgentIndex]
    while (randomUID in Object.keys(selectedAgents)) {
      randomAgentIndex = Math.floor(Math.random() * listOfAgents.length)
      randomUID = listOfAgents[randomAgentIndex]
    }
    addAgent(randomUID)
  }

  function removeAll() {
    setSelectedAgents({})
    setMaximumTimestamp(null)
    setMinimumTimestamp(null)
  }


  return (
    <div className='sidebar'>
      <Row>
        <h6>Dataset</h6>
        <InputGroup size="sm" className="mb-3">
          <Form.Select defaultValue={selectData} onChange={(e) => selectData(e.target.value)} >
            <option value={''} selected={selectData === ''} disabled>Select dataset</option>
            {
              listOfDatasets.map(d => <option value={d} selected={selectData === d}>{d}</option>)
            }
          </Form.Select>
        </InputGroup>
        {(selectedDataset !== '') ? <small>Retrived {totalAgents} agents.</small> : <small>No dataset selected.</small>}

      </Row>

      <hr />
      <Row>
        <h6>Layer Controls</h6>
        <Form>
          <Form.Check // prettier-ignore
            type="switch"
            id="aoi"
            label="AOI Boundary"
            disabled={selectedDataset === '' || Object.keys(aoiBound).length === 0}
            onChange={() => setShowAoiBound(!showAoiBound)}
          />
          <Form.Check // prettier-ignore
            type="switch"
            label="AOI Hex"
            id="aoi-hex"
            onChange={() => setShowAoiHex(!showAoiHex)}
            disabled={selectedDataset === '' || aoiHex8.length === 0}
          />
          <Form.Check // prettier-ignore
            type="switch"
            label="Building Polygons"
            id="aoi-hex"
            onChange={() => setShowBuildingPolygons(!showBuildingPolygons)}
            disabled={selectedDataset === '' || buildingPolygons.length === 0}
          />
        </Form>

      </Row>

      <hr />

      <Row >
        <h6>Agent Selection</h6>

        <InputGroup size="sm" className="mb-3">
          <Form.Control id='input-agent-uids'
            placeholder='Enter UIDs (e.g. 45, 22)'
            onKeyDown={() => console.log('key')}
            value={agentInput}
            onChange={e => setAgentInput(e.target.value)}
            disabled={selectedDataset === ''}

          >

          </Form.Control>
          <Button variant='dark'
            onClick={addAgents}
            disabled={selectedDataset === ''}
          >Add</Button>
        </InputGroup>

        <div className='mb-3'>
          <small>{Object.keys(selectedAgents).length} agents selected.</small>
        </div>

        <ButtonGroup size="sm" className="mb-3">
          <Button variant='secondary' onClick={removeAll} disabled={selectedDataset === ''}>Remove All</Button>
          <Button variant='dark' onClick={addRandomAgent} disabled={selectedDataset === ''}>Add Random</Button>
        </ButtonGroup>

        <div className='mb-3'>
          <Stack gap={1}>
            {Object.keys(selectedAgents).map(uid => {
              const badge =
                <div className='agent-pill'
                  style={{ backgroundColor: selectedAgents[uid]['color'] }}
                  key={`selected-agent-badge-${uid}`}
                >
                  <div className='agent-pill-name'>Agent {uid}</div>
                  <div className='agent-pill-remove'>
                    <FontAwesomeIcon className='agent-pill-remove-x' icon={faRemove} onClick={() => removeAgent(uid)} />
                  </div>
                </div>
              return (badge)
            })}
          </Stack>
        </div>


      </Row>

      <hr />

      {/* <Row>
        <h6>Filtering</h6>
        <InputGroup size="sm" className="mb-3">
          <Form.Control
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"
            value={'To be implemented as needed'}
            disabled
          />
        </InputGroup>

        <ButtonGroup size='sm' className='mb-3'>
          <Button variant='secondary' disabled>Reset</Button>
          <Button variant='dark' disabled>Apply</Button>
        </ButtonGroup>
      </Row> 

      <hr />

      */}
    </div>
  );
}

export default Sidebar;
