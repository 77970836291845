import { Col, Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { useAtom } from 'jotai';
import { editingDataframeAtom, viewingDataframeAtom } from '../HumoNetApp-atoms';

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import { dataframeListAtom, loadedDataframesDictAtom } from '../dataframes/Dataframes-atom';
import { useEffect, useState } from 'react';



function DataFrameViewer(props) {

    const [editingDataframe, setEditingDataframe] = useAtom(editingDataframeAtom)
    const [viewingDataframe, setViewingDataframe] = useAtom(viewingDataframeAtom)
    const [dataframeList, setDataframeList] = useAtom(dataframeListAtom)
    const [loadedDataframesDict, setLoadedDataframesDict] = useAtom(loadedDataframesDictAtom)


    return (
        <>
            {
                viewingDataframe !== '' ?
                    <>
                        <Row className='my-3'>
                            <Col className='my-auto'>
                                <h6>View Dataframe</h6>
                            </Col>

                            <Col className='ms-auto d-flex justify-content-end'>
                                <Button className='df-action-btn' variant='outline-secondary' size='sm' onClick={() => setViewingDataframe('')}>
                                    <FontAwesomeIcon icon={faX} /> close viewer
                                </Button>

                            </Col>

                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <AceEditor
                                    height="400px"
                                    width='100%'
                                    value={JSON.stringify(loadedDataframesDict[props.dataframe.name])}
                                    readOnly
                                    mode="text"
                                    theme="github"
                                    fontSize="14px"
                                    highlightActiveLine={true}
                                    setOptions={{
                                        enableLiveAutocompletion: true,
                                        showLineNumbers: true,
                                        tabSize: 2,
                                        wrap: true
                                    }}
                                />
                            </Col>
                        </Row>
                    </>

                    :

                    <div></div>
            }
        </>
    );
}

export default DataFrameViewer;