import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMap } from '@fortawesome/free-solid-svg-icons';
import './navbar.css'
import { useAtom, useAtomValue } from 'jotai';
import { currentPageAtom, firstNameAtom, lastNameAtom, loggedInAtom, selectedAgentsAtom, selectedDatasetAtom } from '../../App-atoms';


function NavBar() {

    const firstName = useAtomValue(firstNameAtom)
    const lastName = useAtomValue(lastNameAtom)
    const [loggedIn, setLoggedIn] = useAtom(loggedInAtom)
    const [selectedDataset, setSelectedDataset] = useAtom(selectedDatasetAtom)
    const [selectedAgents, setSelectedAgents] = useAtom(selectedAgentsAtom)

    const [currentPage, setCurrentPage] = useState('')

    function updateCurrentPage(e) {
        const page = e.target.hash
        setCurrentPage(page)
        setSelectedDataset('')
        setSelectedAgents({})
    }

    function logOut() {
        sessionStorage.removeItem('loggedInUser')
        setLoggedIn(false)
    }

    function shouldBeActive(p) {
        // const currentPage = sessionStorage.getItem('currentPage')
        return currentPage === p
    }

    return (
        <Navbar bg="dark" data-bs-theme="dark">
            <Container className='mx-2 mw-100'>
                <Navbar.Brand className='user-select-none'>
                    <FontAwesomeIcon icon={faMap} />
                    &nbsp;
                    TraVis
                </Navbar.Brand>
                <Nav className="me-auto" >

                    <Nav.Link
                        href="#trailwind"
                        active={shouldBeActive('#trailwind')}
                        onClick={updateCurrentPage}
                    >
                        TrailWind
                    </Nav.Link>

                    <Nav.Link
                        href="#humonet"
                        active={shouldBeActive('#humonet')}
                        onClick={updateCurrentPage}
                    >
                        HumoNet
                    </Nav.Link>

                    <Nav.Link
                        href="#trakit"
                        active={shouldBeActive('#trakit')}
                        onClick={updateCurrentPage}
                    >
                        TraKit
                    </Nav.Link>

                    <Nav.Link
                        href="#presentation"
                        active={shouldBeActive('#presentation')}
                        onClick={updateCurrentPage}
                        disabled
                    >
                        Presentation
                    </Nav.Link>

                    <NavDropdown title="Individual" id="basic-nav-dropdown" hidden>
                        <NavDropdown.Item href="">Geospatial Movement</NavDropdown.Item>
                        <NavDropdown.Item href="">Patterns of Life</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item disabled href="">Custom Dashboard</NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="Population" id="basic-nav-dropdown" hidden>
                        <NavDropdown.Item href="">Geospatial Movement</NavDropdown.Item>
                        <NavDropdown.Item href="">Patterns of Life</NavDropdown.Item>
                        <NavDropdown.Item href="">Metrics</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item disabled href="">Custom Dashboard</NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown disabled title="Other" id="basic-nav-dropdown" hidden>
                        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">
                            Another action
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">
                            Separated link
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>

                <Nav>
                    <NavDropdown title={'Help'} id="nav-help-menu">
                        <NavDropdown.Item href='' target='_blank' rel="noopener noreferrer" disabled>Report Issues</NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title={firstName + ' ' + lastName} id="nav-user-menu">
                        <NavDropdown.Item onClick={logOut} disabled={!loggedIn}>Logout</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Container>
        </Navbar>
    );
}

export default NavBar;