import { Row, Form, InputGroup, Button, ButtonGroup, Badge, Stack } from 'react-bootstrap';
import './sidebar.css';
import { useAtom, useAtomValue } from 'jotai';
import { totalAgentsAtom, listOfAgentsAtom } from '../TrailWindApp-atoms';
import { listOfDatasetsAtom, loadingAtom, revisionAtom, selectedDatasetAtom, tokenAtom, unameAtom } from '../../../App-atoms';
import { selectedAgentsAtom } from '../../../App-atoms';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove } from '@fortawesome/free-solid-svg-icons';
import { get_list_of_agents, get_test_pol_data, get_train_pol_data } from '../tw_data_utils';
import { trajectoryAtom, polTrainAtom, polTrainLocationsAtom, polTestAtom, polTestLocationsAtom, filterUpperTimeAtom, filterLowerTimeAtom } from '../TrailWindApp-atoms';
import { maximumTimeAtom, minimumTimeAtom } from '../dashboards/sim_overview/Sim_Overview-atoms';
import { stopPointsAtom, stopPointsLocationAtom } from '../../../apps/metrics_overview/Metrics_Overview-atoms';
import { COLORS } from '../style_config';
import { get_list_of_datasets } from '../../../components/common/data_util';

function TWSidebar(props) {

  const [listOfDatasets, setListOfDatasets] = useAtom(listOfDatasetsAtom)
  const [selectedDataset, setSelectedDataset] = useAtom(selectedDatasetAtom)
  const [listOfAgents, setListOfAgents] = useAtom(listOfAgentsAtom)
  const [totalAgents, setTotalAgents] = useAtom(totalAgentsAtom)
  const [selectedAgents, setSelectedAgents] = useAtom(selectedAgentsAtom)
  // const [trajectoryData, setTrajectoryData] = useAtom(trajectoryAtom)
  const [polTrainData, setPOLTrainData] = useAtom(polTrainAtom)
  const [polTrainLocationsData, setPOLTrainLocationsData] = useAtom(polTrainLocationsAtom)
  const [polTestData, setPOLTestData] = useAtom(polTestAtom)
  const [polTestLocationsData, setPOLTestLocationsData] = useAtom(polTestLocationsAtom)
  const [minimumTimestamp, setMinimumTimestamp] = useAtom(minimumTimeAtom)
  const [maximumTimestamp, setMaximumTimestamp] = useAtom(maximumTimeAtom)
  const [filterUpperTime, setFilterUpperTime] = useAtom(filterUpperTimeAtom)
  const [filterLowerTime, setFilterLowerTime] = useAtom(filterLowerTimeAtom)
  const [loading, setLoading] = useAtom(loadingAtom)
  const uname = useAtomValue(unameAtom)
  const token = useAtomValue(tokenAtom)

  const [rev, setRev] = useAtom(revisionAtom)
  const [agentInput, setAgentInput] = useState('')

  useEffect(() => {
    get_list_of_datasets(uname, token).then(res => {
      setListOfDatasets(res.listOfDatasets)
    })
  }, []);

  function load_agents(dataset) {
    setLoading(true)
    console.log(uname, token)
    console.log('load_agents')
    Promise.all([get_list_of_agents(dataset, uname, token)]).then(res => {
      console.log(res)
      setListOfAgents(res[0].listOfAgents)
      setTotalAgents(res[0].numberOfAgents)
      setLoading(false)
    }).catch(err => {
      
    })
  }

  function selectData(d) {

    if (d === '') {
      setSelectedDataset('')
    }
    else {
      setSelectedDataset(d)
      setMinimumTimestamp(null)
      setMaximumTimestamp(null)
      setFilterLowerTime(Number.MIN_VALUE)
      setFilterUpperTime(Number.MAX_VALUE)
      setSelectedAgents({})
      setPOLTrainData({})
      setPOLTrainLocationsData({})
      setPOLTestData({})
      setPOLTestLocationsData({})
      // console.log(d, selectedDataset)
      load_agents(d)
    }
  }


  function addAgents() {
    var input = agentInput.trim()
    console.log(input)
    try {
      input = input.split(',')
      input = input.map(x => x.trim())
      for (const uid of input) {
        console.log(uid)
        validateAgent(uid)
      }
      console.log('all agents are valid')

      setLoading(true)

      //   const trajectoryPromises = input.map(uid => get_trajectory_data(uid, selectedDataset, uname, token))
      const polTrainPromises = input.map(uid => get_train_pol_data(uid, selectedDataset, uname, token))
      const polTestPromises = input.map(uid => get_test_pol_data(uid, selectedDataset, uname, token))
      //   const stopPointPromises = input.map(uid => get_trakit_stop_point_data(uid, selectedDataset, uname, token))

      Promise.all(polTrainPromises.concat(polTestPromises)).then(values => {
        var newSelectedAgent = { ...selectedAgents }
        var newPOLTrainData = { ...polTrainData }
        var newPOLTrainLocationsData = { ...polTrainLocationsData }

        var newPOLTestData = { ...polTestData }
        var newPOLTestLocationsData = { ...polTestLocationsData }

        var newMinTime = Number.MAX_VALUE
        var newMaxTime = Number.MIN_VALUE


        for (var i = 0; i < input.length; i++) {
          const uid = input[i]
          newSelectedAgent[uid] = { 'color': COLORS[Object.keys(newSelectedAgent).length % COLORS.length] }
          //   newMinTime = Math.min(newMinTime, values[i]['minTimestamp'])
          //   newMaxTime = Math.max(newMaxTime, values[i]['maxTimestamp'])

          newPOLTrainData[uid] = values[i]['polData']
          newPOLTrainLocationsData[uid] = values[i]['polLocations']

          newPOLTestData[uid] = values[i + input.length]['polData']
          newPOLTestLocationsData[uid] = values[i + input.length]['polLocations']

          var times = newPOLTrainData[uid].map(r => { return r.start_time })

          if (maximumTimestamp == null || minimumTimestamp == null) {
            setMaximumTimestamp(Math.max(...times))
            setMinimumTimestamp(Math.min(...times))
          }
          else {
            setMaximumTimestamp(Math.max(Math.max(...times), maximumTimestamp))
            setMinimumTimestamp(Math.min(Math.min(...times), minimumTimestamp))
          }

        }


        // setMinimumTimestamp(newMinTime)
        // setMaximumTimestamp(newMaxTime)
        setSelectedAgents(newSelectedAgent)

        setPOLTrainData(newPOLTrainData)
        setPOLTrainLocationsData(newPOLTrainLocationsData)

        setPOLTestData(newPOLTestData)
        setPOLTestLocationsData(newPOLTestLocationsData)


        setLoading(false)
      })

      setAgentInput('')
    }
    catch (e) {
      // setRev(rev + 1)
      alert(e.message)
    }
  }

  function validateAgent(uid) {
    if (uid == '') {
      throw Error('Empty UID is invalid. This may be due a trailing comma.')
    }
    else if (Object.keys(selectedAgents).includes(uid)) {
      throw Error(`Repeated UID detected. ${uid} is already selected.`)
    }
    else if (!listOfAgents.includes(parseInt(uid))) {
      console.log(listOfAgents)
      throw Error(`Agent ${uid} does not exist in the dataset.`)
    }
  }

  function addAgent(uid) {
    setLoading(true)
    var newSelectedAgents = { ...selectedAgents }
    newSelectedAgents[uid] = { 'color': COLORS[Object.keys(newSelectedAgents).length % COLORS.length] }
    setSelectedAgents(newSelectedAgents)

    Promise.all([
      get_train_pol_data(uid, selectedDataset, uname, token),
      get_test_pol_data(uid, selectedDataset, uname, token),
    ]).then((res) => {

      var newPOLTrainData = { ...polTrainData }
      var newPOLTrainLocationsData = { ...polTrainLocationsData }

      var newPOLTestData = { ...polTestData }
      var newPOLTestLocationsData = { ...polTestLocationsData }

      newPOLTrainData[uid] = res[0]['polData']
      newPOLTrainLocationsData[uid] = res[0]['polLocations']

      newPOLTestData[uid] = res[1]['polData']
      newPOLTestLocationsData[uid] = res[1]['polLocations']

      var times = newPOLTrainData[uid].map(r => { return r.start_time })

      if (maximumTimestamp == null || minimumTimestamp == null) {
        setMaximumTimestamp(Math.max(...times))
        setMinimumTimestamp(Math.min(...times))
      }
      else {
        setMaximumTimestamp(Math.max(Math.max(...times), maximumTimestamp))
        setMinimumTimestamp(Math.min(Math.min(...times), minimumTimestamp))
      }

      setPOLTrainData(newPOLTrainData)
      setPOLTrainLocationsData(newPOLTrainLocationsData)

      setPOLTestData(newPOLTestData)
      setPOLTestLocationsData(newPOLTestLocationsData)


      setLoading(false)
      // console.log(newPOLLocationsData)
    })
  }

  function removeAgent(uid) {
    delete selectedAgents[uid]
    if (Object.keys(selectedAgents).length == 0) {
      setMaximumTimestamp(null)
      setMinimumTimestamp(null)
    }
    // setRev(rev + 1)
    var newSelectedAgents = { ...selectedAgents }
    setSelectedAgents(newSelectedAgents)
  }

  function addRandomAgent() {
    var randomAgentIndex = Math.floor(Math.random() * listOfAgents.length)
    var randomUID = listOfAgents[randomAgentIndex]
    while (randomUID in Object.keys(selectedAgents)) {
      randomAgentIndex = Math.floor(Math.random() * listOfAgents.length)
      randomUID = listOfAgents[randomAgentIndex]
    }
    addAgent(randomUID)
  }

  function removeAll() {
    setSelectedAgents({})
    setMaximumTimestamp(null)
    setMinimumTimestamp(null)
  }


  return (
    <div className='sidebar'>
      <Row>
        <h6>Dataset</h6>
        <InputGroup size="sm" className="mb-3">
          <Form.Select defaultValue={selectedDataset} onChange={(e) => selectData(e.target.value)} >
            <option value={''} disabled selected={selectData === ''}>Select dataset</option>
            <option value={'pol_v4_1_knoxville_200k'} >pol_v4_1_knoxville_200k</option>
            {
              listOfDatasets.map(d => <option value={d} disabled>{d}</option>)
            }
          </Form.Select>
        </InputGroup>
        {(selectedDataset !== '') ? <small>Retrived {totalAgents} agents.</small> : <small>No dataset selected.</small>}

      </Row>



      <hr />

      <Row >
        <h6>Agent Selection</h6>

        <InputGroup size="sm" className="mb-3">
          <Form.Control id='input-agent-uids'
            placeholder='Enter UIDs (e.g. 45, 22)'
            onKeyDown={() => console.log('key')}
            value={agentInput}
            onChange={e => setAgentInput(e.target.value)}
            disabled={selectedDataset === ''}

          >

          </Form.Control>
          <Button variant='dark'
            onClick={addAgents}
            disabled={selectedDataset === ''}
          >Add</Button>
        </InputGroup>

        <div className='mb-3'>
          <small>{Object.keys(selectedAgents).length} agents selected.</small>
        </div>

        <ButtonGroup size="sm" className="mb-3">
          <Button variant='secondary' onClick={removeAll} disabled={selectedDataset === ''}>Remove All</Button>
          <Button variant='dark' onClick={addRandomAgent} disabled={selectedDataset === ''}>Add Random</Button>
        </ButtonGroup>

        <div className='mb-3'>
          <Stack gap={1}>
            {Object.keys(selectedAgents).map(uid => {
              const badge =
                <div className='agent-pill'
                  style={{ backgroundColor: selectedAgents[uid]['color'] }}
                  key={`selected-agent-badge-${uid}`}
                >
                  <div className='agent-pill-name'>Agent {uid}</div>
                  <div className='agent-pill-remove'>
                    <FontAwesomeIcon className='agent-pill-remove-x' icon={faRemove} onClick={() => removeAgent(uid)} />
                  </div>
                </div>
              return (badge)
            })}
          </Stack>
        </div>


      </Row>

      <hr />

      <Row>
        <h6>Filtering</h6>
        <InputGroup size="sm" className="mb-3">
          {/* <InputGroup.Text id="inputGroup-sizing-sm">To be added</InputGroup.Text> */}
          <Form.Control
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"
            value={'To be implemented as needed'}
            disabled
          />
        </InputGroup>

        <ButtonGroup size='sm' className='mb-3'>
          <Button variant='secondary' disabled>Reset</Button>
          <Button variant='dark' disabled>Apply</Button>
        </ButtonGroup>
      </Row>

      <hr />
    </div>
  );
}

export default TWSidebar;
