import { Col, Container, Row } from 'react-bootstrap';
import PlaceHolderReact from '../../components/place_holder/place_holder_react';
import '../trailwind_app/dashboards/sim_overview/Sim_Overview.css'
import SignalChart from '../../components/signal_chart/signal_chart';
import { maximumTimeAtom, minimumTimeAtom, timeAtom, trajectoryAtom } from '../trailwind_app/dashboards/sim_overview/Sim_Overview-atoms';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect } from 'react';
import Sidebar from '../../components/overview/sidebar/sidebar';
import TimeControls from '../../components/timecontrols/timecontrols';
import { revisionAtom, selectedAgentsAtom } from '../../App-atoms';
import { get_trajectory_data } from '../../components/common/data_util';
import LoadingComponent from '../../components/common/loading/loading';
import { loadingAtom } from '../../App-atoms';
import MetricsDeckMap from '../../components/metrics_map/map';
import StopPointsTimelineVis from '../../components/stop_point_vis/stop_point_vis';
import TraKitNavBar from '../trakit_app/navbar/navbar';

function MetricsOverviewPage() {

    console.log('reding overview page')

    const loading = useAtomValue(loadingAtom)

    return (
        <>
            <TraKitNavBar />
            <Row style={{ position: 'relative' }}>
                <Col className={'no-padding'} lg={2}>
                    <Sidebar />
                </Col>
                <Col className={'no-padding'} lg={10}>
                    <Row style={{ 'height': '70%' }}>
                        <MetricsDeckMap />
                    </Row>
                    <Row style={{ 'height': '10%' }}>
                        <TimeControls />
                    </Row>
                    <Row style={{ 'height': '20%' }}>
                        <StopPointsTimelineVis />
                    </Row>
                </Col>
                <div className='loading' hidden={!loading}>
                    <LoadingComponent />
                </div>
            </Row>
        </>
    );
}

export default MetricsOverviewPage;