import { atom } from 'jotai'
import { atomWithHash } from 'jotai-location'


/**
 * backend time
 */
export const timeAtom = atom(-1)

/**
 * loaded data atoms
 */
export const trajectoryAtom = atom({})
export const minimumTimeAtom = atom(null)
export const maximumTimeAtom = atom(null)
export const polAtom = atom({})
export const polLocationsAtom = atom({})


/**
 * filtering criteria atoms
 */
export const filterAgentsAtom = atom([])
export const filterLowerTimeAtom = atom(Number.MIN_VALUE)
export const filterUpperTimeAtom = atom(Number.MAX_VALUE)


