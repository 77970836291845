import { Outlet } from "react-router-dom"

export default function EmptyLayout() {
    return (
        <>
            <main>
                <Outlet />
            </main>

        </>

    )
}
