import { Col, Container, Row, ListGroup, Tab, Button, Form } from 'react-bootstrap';
import './dataframe_editor.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTable, faX } from '@fortawesome/free-solid-svg-icons';
import { useAtom, useAtomValue } from 'jotai';
import { editingDataframeAtom } from '../HumoNetApp-atoms';

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-mysql";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import { dataframeListAtom, loadedDataframesDictAtom } from '../dataframes/Dataframes-atom';
import { save_dataframe, get_dataframes_list } from '../../../components/common/data_util';
import { tokenAtom, unameAtom } from '../../../App-atoms';
import { useEffect, useState } from 'react';


function DataFrameEditor(props) {

    const uname = useAtomValue(unameAtom)
    const token = useAtomValue(tokenAtom)
    const [editingDataframe, setEditingDatafram] = useAtom(editingDataframeAtom)
    const [dataframeList, setDataframeList] = useAtom(dataframeListAtom)
    const [loadedDataframesDict, setLoadedDataframesDict] = useAtom(loadedDataframesDictAtom)



    const [dfName, setDfName] = useState(props.dataframe.name)
    const [dfQuery, setDfQuery] = useState(dataframeList[editingDataframe].query)

    useEffect(()=>{
        if (editingDataframe !== '')
        {
            setDfName(props.dataframe.name)
            setDfQuery(props.dataframe.query)
        }
    }, [editingDataframe])

    function save_df() {
        let df = dataframeList[editingDataframe]
        save_dataframe(uname, token, df.name, dfName, dfQuery).then(values => {
            get_dataframes_list(uname, token).then(values => {
                if (Object.keys(loadedDataframesDict).includes(df.name)){
                    delete loadedDataframesDict[df.name]
                    setLoadedDataframesDict({...loadedDataframesDict})
                }
                setDataframeList([...values.data])
                setEditingDatafram('')
            }).catch(err => {

            })
        })
    }


    console.log('rendering dataframe editor ', editingDataframe, props.editingDataframe)
    return (
        <>
            {
                editingDataframe !== '' ?
                    <>
                        <Row className='my-3'>
                            <Col className='my-auto'>
                                <h6>Edit Dataframe</h6>
                            </Col>

                            <Col className='ms-auto d-flex justify-content-end'>
                                <Button className='df-action-btn' variant='outline-secondary' size='sm' onClick={() => setEditingDatafram('')}>
                                    <FontAwesomeIcon icon={faX} /> close editor
                                </Button>

                                <Button className='df-action-btn' variant='outline-secondary' size='sm' onClick={save_df}>
                                    <FontAwesomeIcon icon={faSave} /> save changes
                                </Button>
                            </Col>

                        </Row>
                        <hr />
                        <Row>
                            <Form>
                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                                    <Form.Label column sm="3">
                                        Name
                                    </Form.Label>
                                    <Col sm="9">
                                        <Form.Control value={dfName} onChange={(e) => {setDfName(e.target.value)}} />
                                    </Col>
                                </Form.Group>
                            </Form>

                            <Form>
                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                                    <Form.Label column sm="3">
                                        Query (SQL)
                                    </Form.Label>
                                    <Col sm="9">
                                        <AceEditor
                                            height="300px"
                                            width='100%'
                                            value={dfQuery}
                                            onChange={setDfQuery}
                                            mode="mysql"
                                            theme="github"
                                            fontSize="14px"
                                            highlightActiveLine={true}
                                            setOptions={{
                                                enableLiveAutocompletion: true,
                                                showLineNumbers: true,
                                                tabSize: 2
                                            }}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form>


                        </Row>
                    </>

                    :

                    <div></div>
            }
        </>
    );
}

export default DataFrameEditor;