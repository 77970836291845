import './signal_chart.css';
import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import Plot from 'react-plotly.js';
import Loading from '../common/loading';

function SignalChart(props) {
    const [data, setData] = useState(null)

    function loadData() {
        fetch("/api/get_signal_data").then((res) =>
            res.json().then((data) => {
                setData(data)
            })
        );
    }

    // useEffect(() => { loadData() }, [])

    function reLayout(e) {
        console.log(e)
        if ('autosize' in e)
            return

        setData(null)
        // fetch("/api/get_updated_data", {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/json"
        //     },
        //     body: JSON.stringify({
        //         "data": e
        //     })
        // }).then((res) =>
        //     res.json().then((data) => {
        //         setData(data)
        //     })
        // )
    }

    if (data === null) {
        return (
            <Card>
                <Loading />
            </Card >
        )
    }

    var ind = 0;

    var plotly_data = []
    var plotly_title = ""
    console.log(data)
    if ("raw_signals" in data) {
        const x_time = data.raw_signals.index.map(t => new Date(t*1000))
        const y = [].concat(...data.raw_signals.data.map(x => x[ind]))
        console.log('RAW DATA')
        plotly_data = [
            {
                x: x_time,
                y: y,
                type: 'scatter',
                mode: 'lines',
                marker: { color: 'black' },
                showlegend: false
            },
        ]
        plotly_title = data.raw_signals.columns[ind]
    }
    else {
        const x_time = data.mean.index.map(t => new Date(t*1000))
        const y = [].concat(...data.mean.data.map(x => x[ind]))
        const y_lower = [].concat(...data.min.data.map(x => x[ind]))
        const y_upper = [].concat(...data.max.data.map(x => x[ind]))

        console.log(x_time)
        plotly_data = [

            {
                x: x_time,
                y: y_upper,
                type: 'scatter',
                mode: 'lines',
                marker: { color: '#444' },
                line: { width: 0 },
                showlegend: false,
                hoverinfo: 'skip'
            },
            {
                x: x_time,
                y: y_lower,
                fill: 'tonexty',
                fillcolor: 'rgba(68, 68, 68, 0.3)',
                type: 'scatter',
                mode: 'lines',
                marker: { color: '#444' },
                line: { width: 0 },
                showlegend: false,
                hoverinfo: 'skip'
            },
            {
                x: x_time,
                y: y,
                type: 'scatter',
                mode: 'lines',
                marker: { color: 'black' },
                showlegend: false
            },
        ]
        plotly_title = data.mean.columns[ind]
    }

    return (
        <Card>
            <div className='plot-container'>
                <Plot
                    data={plotly_data}
                    useResizeHandler={true}
                    layout={{
                        title: plotly_title,
                        margin: { l: 40, r: 40, t: 40, b: 40 },
                    }}
                    style={{ width: '100%', height: '100%' }}
                    onRelayout={reLayout}
                />
            </div>
        </Card >

    );
}

export default SignalChart;
