import { faBagShopping, faBasketball, faBolt, faBookOpen, faBriefcase, faBuilding, faBus, faCartShopping, faCircle, faCircleQuestion, faDove, faDumbbell, faFlaskVial, faGasPump, faHandsPraying, faHouse, faIndustry, faLocationDot, faLocationPin, faPersonMilitaryPointing, faPersonSwimming, faSchool, faStethoscope, faStore, faTicket, faTractor, faUserDoctor, faUtensils, faWineGlass } from '@fortawesome/free-solid-svg-icons';

export function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });
  
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    // return result ? {
    //   r: parseInt(result[1], 16),
    //   g: parseInt(result[2], 16),
    //   b: parseInt(result[3], 16)
    // } : null;

    return result ? [parseInt(result[1], 16) , parseInt(result[2], 16), parseInt(result[3], 16)] : null;
  }



  export function hexToRgba(hex, alpha) {
    // console.log(alpha)
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });
  
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    // return result ? {
    //   r: parseInt(result[1], 16),
    //   g: parseInt(result[2], 16),
    //   b: parseInt(result[3], 16)
    // } : null;

    return result ? [parseInt(result[1], 16) , parseInt(result[2], 16), parseInt(result[3], 16), parseInt(alpha*255)] : null;
  }

export const COLORS = [
    '#ff7f00', '#33a02c', '#e31a1c', '#b2df8a', '#fb9a99',
    '#1f78b4', '#6a3d9a', '#b15928', '#cab2d6', '#fdbf6']

export const RGB_COLORS = COLORS.map(c => hexToRgb(c))

export const POL_ICONS = {
    'night_club': faWineGlass,
    'manufacturing': faIndustry,
    'theater': faTicket,
    'aquatic': faPersonSwimming,
    'market': faStore,
    'recreation': faDumbbell,
    'power_plant': faBolt,
    'military': faPersonMilitaryPointing,
    'kiosk': faLocationDot,
    'chemical_refining': faFlaskVial,
    'food': faUtensils,
    'park': faLocationDot,
    'store': faStore,
    'sports': faBasketball,
    'education': faSchool,
    'religious': faDove,
    'geographic': faLocationDot,
    'healthcare': faUserDoctor,
    'agriculture': faTractor,
    'hospitality': faLocationDot,
    'residential': faHouse,
    'administrative': faLocationDot,
    'public_service': faLocationDot,
    'transportation': faBus,
    'office_building': faBuilding,
    'filling_stations': faGasPump,
    'learning_facilities/tourism': faLocationDot
}


const STOP_POINT_ICONS = {
  'home': faHouse,
  'work1': faBriefcase,
  'work2': faBriefcase,
  'work3': faBriefcase,
  'Uncategorized': faCircleQuestion,
  'Education': faBookOpen,
  'Re-supply': faCartShopping,
  'Religious': faDove,
  'Healthcare': faStethoscope,
  'Residential': faBuilding,
  'Entertainment': faTicket
}


const STOP_POINT_RADIUS = {
  'home': 300,
  'work1': 30,
  'work2': 30,
  'work3': 30
}

export function get_stop_point_icon(stop_point_str) {
  if (Object.keys(STOP_POINT_ICONS).includes(stop_point_str))
  {
    return STOP_POINT_ICONS[stop_point_str]
  } 
  else{
    return null
  }
}

export function get_pol_icon(pol_str) {
    if (Object.keys(POL_ICONS).includes(pol_str))
    {
      return POL_ICONS[pol_str]
    } 
    else{
      return null
    }
  }

export function get_collision_priority(point) {
  const high_priority = ['home', 'work1', 'work2', 'work3']
  if (high_priority.includes(point))
  {
    return Math.floor(Math.random() * 500) + 500
  }
  else {
    return Math.floor(Math.random() * 500) - 500
  }
}