import Plot from 'react-plotly.js'
import './dashboard.css'
import { minimumTimeAtom, maximumTimeAtom, filterLowerTimeAtom, filterUpperTimeAtom } from '../sim_overview/Sim_Overview-atoms';
import { useAtom, useAtomValue } from 'jotai';
import { hoverTimeAtom, hoverTimeEpsilonAtom } from '../../../../components/timecontrols/timecontrols-atoms';
import { datasetMetadataAtom, selectedAgentsAtom, selectedDatasetAtom } from '../../../../App-atoms';

const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

/**
 * 
 * @param {*} start start time in ms
 * @param {*} end end time in ms  
 * @param {*} length the length of each interval in ms
 * @returns 
 */
function getTimesArray(start, end, length) {
    let times = []

    while (start <= end) {
        times.push(start)
        start += length
    }
    // console.log('TIMES', times)
    return times
}


/**
 * 
 * @param {*} x is a timestamp in ms
 * @returns 
 */
function getTimeColor(x, utc_offset) {
    // should be in local browser timezone
    var d = new Date(x)

    // convert to utc
    var utc = d.getTime() + (d.getTimezoneOffset() * 60000)

    // create new Date object for different city
    // using supplied offset
    var nd = new Date(utc + (3600000 * utc_offset));


    let h = nd.getHours() + nd.getMinutes() / 60
    return (Math.sin((Math.PI / 12) * (h) - Math.PI / 2) + 1) / 2
}

function getTimeString(x, tz, utc_offset) {
    // should be in local browser timezone
    var d = new Date(x)

    // convert to utc
    var utc = d.getTime() + (d.getTimezoneOffset() * 60000)

    // create new Date object for different city
    // using supplied offset
    d = new Date(utc + (3600000 * utc_offset));

    return DAYS[d.getDay()] + '\n' + d.toLocaleDateString(tz) + ' ' + `${d.getHours()}:${d.getMinutes()}`
}


function TWTimeControls(props) {
    console.log('rendering time controls')

    const minimumTime = useAtomValue(minimumTimeAtom);
    const maximumTime = useAtomValue(maximumTimeAtom);
    const selectedAgents = useAtomValue(selectedAgentsAtom)

    const [filterLowerTime, setFilterLowerTime] = useAtom(filterLowerTimeAtom)
    const [filterUpperTime, setFilterUpperTime] = useAtom(filterUpperTimeAtom)
    const [hoverTime, setHoverTime] = useAtom(hoverTimeAtom)
    const [hoverTimeEpsilon, setHoverTimeEpsilon] = useAtom(hoverTimeEpsilonAtom)

    const selectData = useAtomValue(selectedDatasetAtom)
    const datasetMetadata = useAtomValue(datasetMetadataAtom)

    var tz = 'en-US'
    var utc_offset = 0
    
    if (selectData !== '')
    {
        // tz = datasetMetadata[selectData]['timezone']
        utc_offset = datasetMetadata[selectData]['utc_offset']
    }


    function relayout(e) {
        console.log('TIME RELAYOUT', e)
        if (Object.keys(e).includes('xaxis.range[0]')) {
            const newMin = e['xaxis.range[0]'];
            const newMax = e['xaxis.range[1]'];

            setFilterLowerTime(newMin)
            setFilterUpperTime(newMax)

            if (newMax - newMin > 6.048e+8) { // more than 7 day interval
                console.log('1 day total interval')
                setHoverTimeEpsilon(4.32e+7) // 12 hours (1 day total interval)
            }
            else if (newMax - newMin > 2.592e+8) { // more than 3 days interval (and less than 7 days)
                console.log('1 hour total interval')
                setHoverTimeEpsilon(1.8e+6) // 30 minutes (1 hour total interval)
            }
            // else if (newMax - newMin > 4.32e+7) { // more than 12 hours (and less than 3 days)
            else {
                console.log('1 minute total interval')
                setHoverTimeEpsilon(900000) // 15 mins (30 minute total interval)
            }

            console.log('hover epsilon', hoverTimeEpsilon)


        }
        else if (Object.keys(e).includes('xaxis.range')) {
            const newMin = e['xaxis.range'][0];
            const newMax = e['xaxis.range'][1];

            setFilterLowerTime(newMin)
            setFilterUpperTime(newMax)
        }
        else {
            setFilterLowerTime(minimumTime)
            setFilterUpperTime(maximumTime)
            setHoverTimeEpsilon(4.32e+7)
        }
    }


    function hoverTimeline(e) {
        const hover_time = e.xvals[0]
        setHoverTime(hover_time)
    }

    function unhoverTimeline(e) {
        setHoverTime(null)
    }

    const ONE_DAY_MS = 8.64e+7
    var suggestedInterval = ONE_DAY_MS // one day


    var visibleLowerTime = Math.max(minimumTime, filterLowerTime)
    console.log(minimumTime, filterLowerTime, 'take maximum for lower visible')
    var visibleUpperTime = Math.min(maximumTime, filterUpperTime)
    console.log(maximumTime, filterUpperTime, 'take minimum for upper visiper')


    if (minimumTime > Number.MIN_VALUE && maximumTime < Number.MAX_VALUE) {
        var numBuckets = 10000;
        if (visibleUpperTime - visibleLowerTime < numBuckets) {
            numBuckets = visibleUpperTime - visibleLowerTime
        }
        // console.log(visibleUpperTime - visibleLowerTime)
        suggestedInterval = (visibleUpperTime - visibleLowerTime) / (numBuckets)
        // console.log(visibleLowerTime, visibleUpperTime, Math.max(suggestedInterval, 10000))
    }

    var xValues = getTimesArray(Math.floor(visibleLowerTime), Math.ceil(visibleUpperTime), Math.floor(suggestedInterval));
    var yValues = xValues.map((v, i) => getTimeColor(v, utc_offset));

    var textValues = xValues.map((v, i) => getTimeString(v, tz, utc_offset))

    var colorscaleValue = [
        [0, '#001f3f'],
        [1, '#C6FCFF']
    ];

    const plotlyData = [
        {
            name: '',
            mode: 'lines',
            x: xValues,
            y: yValues,
            line: {
                color: 'lightgray',
                width: 4
            },
            text: textValues,
            hoverInfo: 'text',
            hovertemplate: '<b>%{text}</b>',
            // zmin: 0,
            // zmax: 1,
            // text: textValues,
            // colorscale: colorscaleValue,
            // showscale: true,
            // hoverinfo: 'text',
            // opacity: 1,
            // colorbar: {
            //     outlinecolor: 'transparent',
            //     tickvals: [0, 1],
            //     ticktext: ['Midnight', 'Noon'],
            //     thickness: 10,
            // },
        }
    ]

    // layers.push(new LineLayer({
    //     id: `pol-lines-${uid}`,
    //     data: props.polData[uid],
    //     pickable: true,
    //     getWidth: 3,
    //     lineWidthMaxPixels: 5,
    //     lineWidthMinPixels: 3,
    //     opacity: 0.1, 
    //     getSourcePosition: d => [d.origin_longitude, d.origin_latitude],
    //     getTargetPosition: d => [d.destination_longitude, d.destination_latitude],
    //     getColor: d => hexToRgb(selectedAgents[uid]['color']),
    //     getFilterValue: d => d.start_time,
    //     filterRange: filterRange,
    //     extensions: [filterExt]
    // }))

    Object.keys(props.polData).map(uid => {
        if (uid in selectedAgents) {
            plotlyData.push({
                name: `Agent ${uid}`,
                mode: 'markers',
                x: props.polData[uid].map(r => r.start_time),
                y: props.polData[uid].map(r => getTimeColor(r.start_time, utc_offset)),
                marker: {
                    color: selectedAgents[uid]['color'],
                    size: 8,
                    opacity: 0.8,
                    line: {
                        color: 'black',
                        opacity: 0.4,
                        width: 1
                    }
                },
                text: props.polData[uid].map(r => `${getTimeString(r.start_time, tz, utc_offset)} (From ${r.origin} to ${r.destination})`),
                hoverInfo: 'text',
                hovertemplate: '%{text}',
            })
        }
    })

    const shapes = []
    if (hoverTime !== null) {
        shapes.push({
            type: 'line',
            x0: hoverTime,
            x1: hoverTime,
            y0: -1,
            y1: 1,
            line: {
                width: 2,
                color: '#001f3f',
                dash: 'dot'
            }
        })

    }



    return (
        <div className='time-controls-div'>
            <div hidden={minimumTime > Number.MIN_VALUE && maximumTime < Number.MAX_VALUE} className='time-controls-mask'>
                <small> Select an agent to load the timeline.</small>
            </div>
            <Plot
                data={plotlyData}
                useResizeHandler={true}
                onRelayout={relayout}
                onHover={hoverTimeline}
                onUnhover={unhoverTimeline}
                layout={{
                    // showlegend: true,
                    hovermode: 'closest',
                    margin: { l: 70, r: 50, t: 20, b: 70 },
                    xaxis: {
                        range: [visibleLowerTime, visibleUpperTime],
                        showgrid: false,
                        zeroline: false,
                        hoverformat: '',
                        hoverInfo: textValues,
                        // visible: true,
                        tickvals: [visibleLowerTime, visibleUpperTime],
                        ticktext: [getTimeString(visibleLowerTime, tz, utc_offset), getTimeString(visibleUpperTime, tz, utc_offset)],
                        rangeslider: {
                            visible: true,
                            thickness: 0.1,
                            range: [minimumTime, maximumTime],

                        }
                    },
                    yaxis: {
                        range: [-0.1, 1.1],
                        fixedrange: true,
                        tickmode: 'array',
                        tickvals: [0, 1],
                        ticktext: ['Midnight', 'Noon'],
                        title: {
                            text: "Time of Day"
                        }
                    },
                    shapes: shapes
                }}
                style={{ width: '100%', height: '100%' }}
            />
        </div>
    );
}
// }

export default TWTimeControls;
