import { atom } from 'jotai'
/**
 * hovers
 */

export const hoverTimeAtom = atom(null)
export const hoverTimeEpsilonAtom = atom(4.32e+7)

/**
 * backend time
 */
export const timeAtom = atom(-1)

/**
 * loaded data atoms
 */
export const trajectoryAtom = atom({})
export const minimumTimeAtom = atom(null)
export const maximumTimeAtom = atom(null)

export const polTrainAtom = atom({})
export const polTrainLocationsAtom = atom({})

export const polTestAtom = atom({})
export const polTestLocationsAtom = atom({})


/**
 * filtering criteria atoms
 */
export const filterAgentsAtom = atom([])
export const filterLowerTimeAtom = atom(Number.MIN_VALUE)
export const filterUpperTimeAtom = atom(Number.MAX_VALUE)

/**
 * list of agent UIDs
 */
export const listOfAgentsAtom = atom([])


/**
 * total number of agents available
 */
export const totalAgentsAtom = atom(null)

export const mapViewAtom = atom({
    longitude: -84.1007,
    latitude: 35.9606,
    zoom: 10.5
})


export const mapStyleAtom = atom('mapbox://styles/mapbox/light-v11')