import React from 'react';
import { useState } from 'react'
import DeckGL from '@deck.gl/react';
import { DataFilterExtension, CollisionFilterExtension } from '@deck.gl/extensions'
import { Map } from 'react-map-gl';
import { TripsLayer } from '@deck.gl/geo-layers'
import { ScatterplotLayer, IconLayer } from '@deck.gl/layers'
import './dashboard.css'
import { useAtom, useAtomValue } from 'jotai';
import { selectedAgentsAtom } from '../../../../App-atoms';
import { hoverTimeAtom, hoverTimeEpsilonAtom } from '../../../../components/timecontrols/timecontrols-atoms';
import { hexToRgba, hexToRgb, get_pol_icon, get_collision_priority, POL_ICONS } from '../../style_config';
import { mapStyleAtom, mapViewAtom } from '../../TrailWindApp-atoms';
import { count } from 'd3';
import { LineLayer, TextLayer } from 'deck.gl';
import {  maximumTimeAtom, minimumTimeAtom } from '../../TrailWindApp-atoms';
import { filterLowerTimeAtom, filterUpperTimeAtom } from '../sim_overview/Sim_Overview-atoms';


// Set your mapbox access token here
const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoibW9uYWRqZW1pIiwiYSI6ImNsb2l0Y29lZDA1ODAycHN1NzVsaWh2Z3gifQ.t_lEPP4SMc_z5Z6Vz7wB6w';


const ICON_MAPPING = {
    marker: { x: 0, y: 0, width: 128, height: 128, mask: true }
};

const MAP_STYLE = {
    false: 'mapbox://styles/mapbox/light-v11',
    true: 'mapbox://styles/monadjemi/clqy57h3300qe01pdb4m2e9od'
}

function svgToDataURL(svg) {
    return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`;
}

function getSVGIcon(life_category) {
    const faicon = get_pol_icon(life_category)
    var sloc_color = 'rgba(0, 0, 0, 1)'
    if (['home', 'work1', 'work2', 'work3'].includes(life_category)) {
        sloc_color = 'rgba(0, 0, 0, 1)'
    }
    if (faicon)
        return `<svg xmlns="http://www.w3.org/2000/svg" fill="${sloc_color}" height="160" width="180" viewBox="0 0 ${faicon.icon[0]} ${faicon.icon[1]}">
    <path d="${faicon.icon[4]}" />
    </svg>`
    else
        return `<svg xmlns="http://www.w3.org/2000/svg" height="300" width="300" viewBox="0 0 30 18">
        <text x="10" y="10">${life_category[0]}</text>
        </svg>`
}



// new DataFilterExtension();

function POLDeckMap(props) {
    const [mapView, setMapView] = useAtom(mapViewAtom)
    const [mapStyle, setMapStyle] = useAtom(mapStyleAtom)
    const [collision_filter_ext, setCollisionFilterExt] = useState(new CollisionFilterExtension)

    function move(e) {
        setMapView(e.viewState)
        if (e.interactionState.isZooming)
        {
            const detailedZoom = e.viewState.zoom > 16
            setMapStyle(MAP_STYLE[detailedZoom])
        }
    }


    function compute_alpha(count, uid) {
        var alpha = count / props.polData[uid].length
        // console.log(alpha)
        var newAlpha = (alpha - visitRatios[uid]['min']) / (visitRatios[uid]['max'] - visitRatios[uid]['min'])
        newAlpha = 0.75 * newAlpha + 0.25
        return newAlpha
    }


    // const trajectoryData = useAtomValue(trajectoryAtom)
    const selectedAgents = useAtomValue(selectedAgentsAtom)
    const filterLowerTime = useAtomValue(filterLowerTimeAtom)
    const filterUpperTime = useAtomValue(filterUpperTimeAtom)
    const maximumTime = useAtomValue(maximumTimeAtom)
    const minimumTime = useAtomValue(minimumTimeAtom)
    const hoverTime = useAtomValue(hoverTimeAtom)
    const hoverTimeEpsilon = useAtomValue(hoverTimeEpsilonAtom)
    // const polLocations = useAtomValue(polLocationsAtom)
    // const polData = useAtomValue(polAtom)

    let filterRange = [filterLowerTime, filterUpperTime]
    // console.log(filterRange)

    const visibleLowerTime = Math.max(minimumTime, filterLowerTime)
    const visibleUpperTime = Math.min(maximumTime, filterUpperTime)

    const [hoverInfo, setHoverInfo] = useState(null);

    const filterExt = new DataFilterExtension({ filterSize: 1 })


    const visitRatios = {}
    Object.keys(props.polLocationsData).map(v => {
        let counts = props.polLocationsData[v].map(l => {
            return l.count / props.polData[v].length
        })
        // console.log(counts)
        visitRatios[v] = { 'min': Math.min(...counts), 'max': Math.max(...counts) }
    })

    
    // if (hoverTime) {
    //     filterRange = [hoverTime - hoverTimeEpsilon,
    //     hoverTime + hoverTimeEpsilon]
    // } 

    // console.log(filterRange)
    // console.log(visitRatios)


    // if (hoverTime) {
    //     filterRange = [hoverTime - hoverTimeEpsilon,
    //     hoverTime + hoverTimeEpsilon]
    // }

    const layers = [
    ];

    function pushToLayers(uid) {

        // layers.push(new ScatterplotLayer({
        //     id: `icon-layer-uid-${uid}`,
        //     data: props.polData[uid],
        //     pickable: true,
        //     stroked: true,
        //     filled: true,
        //     radiusScale: 10,
        //     radiusMinPixels: 5,
        //     radiusMaxPixels: 5,
        //     lineWidthMinPixels: 2,
        //     lineWidthMaxPixels: 2,
        //     getPosition: d => [d.destination_longitude, d.destination_latitude],
        //     getRadius: 40,
        //     pickable: true,
        //     getFillColor: 'rgba(0, 0, 0, 0.1)',
        //     opacity: 0.2,
        //     getLineColor: hexToRgb(selectedAgents[uid]['color']),
        //     onHover: info => { setHoverInfo(info) },
        //     onUnhover: info => { setHoverInfo(null) }
        // })
        // )

        layers.push(new LineLayer({
            id: `pol-lines-${uid}`,
            data: props.polData[uid],
            pickable: true,
            getWidth: 3,
            lineWidthMaxPixels: 5,
            lineWidthMinPixels: 3,
            opacity: 0.1, 
            getSourcePosition: d => [d.origin_longitude, d.origin_latitude],
            getTargetPosition: d => [d.destination_longitude, d.destination_latitude],
            getColor: d => hexToRgb(selectedAgents[uid]['color']),
            getFilterValue: d => d.start_time,
            filterRange: filterRange,
            extensions: [filterExt]
        }))

        layers.push(new ScatterplotLayer({
            id: `pol_${uid}`,
            data: props.polLocationsData[uid],
            pickable: true,
            stroked: true,
            filled: true,
            radiusScale: 8,
            radiusMinPixels: 15,
            radiusMaxPixels: 20,
            lineWidthMinPixels: 0,
            lineWidthMaxPixels: 0,
            getPosition: d => [d.destination_longitude, d.destination_latitude],
            getRadius: 20,
            pickable: true,
            getLineColor: 'rgba(0, 0, 0, 0)',
            // opacity: 0.3,
            getFillColor: d => hexToRgba(selectedAgents[uid]['color'], compute_alpha(d.count, uid)),
            onHover: info => { setHoverInfo(info) },
            onUnhover: info => { setHoverInfo(null) },
            // getFilterValue: d => [d.start_timestamp * 1000, d.stop_timestamp * 1000],
            // filterRange: [filterRangeStopPointStartTime, filterRangeStopPointStopTime],
            collisionGroup: `${uid}`,
            getCollisionPriority: d => d.count + Object.keys(POL_ICONS).indexOf(d.destination),
            extensions: [collision_filter_ext]
        })
        )

        layers.push(new IconLayer({
            id: `pol-icons-${uid}`,
            data: props.polLocationsData[uid],
            getIcon: d => ({
                url: svgToDataURL(getSVGIcon(d.destination)),
                width: 100,
                height: 100,
                anchorY: 90,
                anchorX: 90
            }),
            sizeScale: 1,
            sizeMinPixels: 15,
            sizeMaxPixels: 30,
            getPosition: d => [d.destination_longitude, d.destination_latitude],
            getSize: d => 15,
            // getFilterValue: d => [d.start_timestamp * 1000, d.stop_timestamp * 1000],
            // filterRange: [filterRangeStopPointStartTime, filterRangeStopPointStopTime],
            collisionGroup: `${uid}`,
            getCollisionPriority: d => d.count + Object.keys(POL_ICONS).indexOf(d.destination),
            extensions: [collision_filter_ext]
        }))

        layers.push(new TextLayer({
            id: `pol-count-${uid}`,
            data: props.polLocationsData[uid],
            getPosition: d => [d.destination_longitude, d.destination_latitude],
            getText: d => `${d.count}`,
            getSize: d => 10,
            getTextAnchor: 'start',
            getAlignmentBaseline: 'top',
            getAngle: 0,
            // getFilterValue: d => [d.start_timestamp * 1000, d.stop_timestamp * 1000],
            // filterRange: [filterRangeStopPointStartTime, filterRangeStopPointStopTime],
            collisionGroup: `${uid}`,
            getCollisionPriority: d => d.count + Object.keys(POL_ICONS).indexOf(d.destination),
            extensions: [collision_filter_ext]
        }))

        


    }

    // console.log(props.polData)
    Object.keys(props.polData).forEach(function (uid) {
        if (uid in selectedAgents) {
            // console.log('pushing', uid, 'to layers')
            pushToLayers(uid)
        }
    })

    // console.log('rendering map')
    return (
        <div className='map-div ml-0'>
            <DeckGL
                initialViewState={mapView}
                controller={true}
                layers={layers}
                onViewStateChange={move}
                viewState={mapView}
                getTooltip={(object) => (hoverInfo && hoverInfo.object) && `${hoverInfo.object.destination}\nvisited ${hoverInfo.object.count} time(s)`}
            >
                <Map
                    {...mapView}
                    mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
                    mapStyle={mapStyle} />
            </DeckGL>

        </div>

    );
}

export default POLDeckMap;