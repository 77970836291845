import './timecontrols.css';
import Plot from 'react-plotly.js'
import { minimumTimeAtom, maximumTimeAtom, filterLowerTimeAtom, filterUpperTimeAtom } from '../../apps/trailwind_app/dashboards/sim_overview/Sim_Overview-atoms';
import { useAtom, useAtomValue } from 'jotai';
import { hoverTimeAtom, hoverTimeEpsilonAtom } from './timecontrols-atoms';
import { datasetMetadataAtom, selectedDatasetAtom } from '../../App-atoms';

const DAYS = ["Sun", "Mon", "Tue", "Wed","Thu", "Fri","Sat"]

/**
 * 
 * @param {*} start start time in ms
 * @param {*} end end time in ms  
 * @param {*} length the length of each interval in ms
 * @returns 
 */
function getTimesArray(start, end, length) {
  let times = []

  while (start <= end) {
    times.push(start)
    start += length
  }
  // console.log('TIMES', times)
  return times
}


/**
 * 
 * @param {*} x is a timestamp in ms
 * @returns 
 */
function getTimeColor(x, utc_offset) {
  // should be in local browser timezone
  var d = new Date(x)

  // convert to utc
  var utc = d.getTime() + (d.getTimezoneOffset() * 60000)

  // create new Date object for different city
  // using supplied offset
  var nd = new Date(utc + (3600000 * utc_offset));


  let h = nd.getHours() + nd.getMinutes() / 60
  return (Math.sin((Math.PI / 12) * (h) - Math.PI / 2) + 1) / 2
}

function getTimeString(x, tz, utc_offset) {
  // should be in local browser timezone
  var d = new Date(x)

  // convert to utc
  var utc = d.getTime() + (d.getTimezoneOffset() * 60000)

  // create new Date object for different city
  // using supplied offset
  d = new Date(utc + (3600000 * utc_offset));

  return DAYS[d.getDay()] + '\n' + d.toLocaleDateString(tz) + ' ' + `${d.getHours()}:${d.getMinutes()}`
}


function TimeControls(props) {
  // console.log('rendering time controls')

  const minimumTime = useAtomValue(minimumTimeAtom);
  const maximumTime = useAtomValue(maximumTimeAtom);

  const [filterLowerTime, setFilterLowerTime] = useAtom(filterLowerTimeAtom)
  const [filterUpperTime, setFilterUpperTime] = useAtom(filterUpperTimeAtom)
  const [hoverTime, setHoverTime] = useAtom(hoverTimeAtom)
  const [hoverTimeEpsilon, setHoverTimeEpsilon] = useAtom(hoverTimeEpsilonAtom)

  const selectData = useAtomValue(selectedDatasetAtom)
  const datasetMetadata = useAtomValue(datasetMetadataAtom)

  var tz = 'en-US'
  var utc_offset = -5
  
  if (selectData !== '')
  {
      // tz = datasetMetadata[selectData]['timezone']
      utc_offset = datasetMetadata[selectData]['utc_offset']
  }


  function relayout(e) {
    console.log('TIME RELAYOUT', e)
    if (Object.keys(e).includes('xaxis.range[0]')) {
      const newMin = e['xaxis.range[0]'];
      const newMax = e['xaxis.range[1]'];

      setFilterLowerTime(newMin)
      setFilterUpperTime(newMax)

      if (newMax - newMin > 6.048e+8) { // more than 7 day interval
        console.log('1 day total interval')
        setHoverTimeEpsilon(4.32e+7) // 12 hours (1 day total interval)
      }
      else if (newMax - newMin > 2.592e+8) { // more than 3 days interval (and less than 7 days)
        console.log('1 hour total interval')
        setHoverTimeEpsilon(1.8e+6) // 30 minutes (1 hour total interval)
      }
      // else if (newMax - newMin > 4.32e+7) { // more than 12 hours (and less than 3 days)
      else {
        console.log('1 minute total interval')
        setHoverTimeEpsilon(30000) // 30 seconds (1 minute total interval)
      }
      // else { // less than 12 hours
      //   console.log('instantaneous interval')
      //   setHoverTimeEpsilon(0) // instantaneous
      // }
      console.log('hover epsilon', hoverTimeEpsilon)


    }
    else {
      setFilterLowerTime(Number.MIN_VALUE)
      setFilterUpperTime(Number.MAX_VALUE)
      setHoverTimeEpsilon(4.32e+7)
    }
  }

  function hoverTimeline(e) {
    const hover_time = e.xvals[0]
    // console.log('hover')
    setHoverTime(hover_time)
  }

  function unhoverTimeline(e) {
    // console.log('unhover')
    setHoverTime(null)
  }

  // if (minimumTime == null || maximumTime == null) {
  //   return (
  //     <div className='time-controls-div'>
  //       
  //     </div>
  //   )
  // }
  // else {

  const ONE_DAY_MS = 8.64e+7
  var suggestedInterval = ONE_DAY_MS // one day
  // if (filterLowerTime === null){
  //   setFilterLowerTime(Number.MIN_VALUE)
  // }
  // if (filterUpperTime === null) {
  //   setFilterUpperTime(Number.MAX_VALUE)
  // }

  var visibleLowerTime = Math.max(minimumTime, filterLowerTime)
  console.log(minimumTime, filterLowerTime, 'take maximum for lower visible')
  var visibleUpperTime = Math.min(maximumTime, filterUpperTime)
  console.log(maximumTime, filterUpperTime, 'take minimum for upper visiper')


  if (minimumTime > Number.MIN_VALUE && maximumTime < Number.MAX_VALUE) {
    var numBuckets = 10000;
    if (visibleUpperTime - visibleLowerTime < numBuckets) {
      numBuckets = visibleUpperTime - visibleLowerTime
    }
    // console.log(visibleUpperTime - visibleLowerTime)
    suggestedInterval = (visibleUpperTime - visibleLowerTime) / (numBuckets)
    // console.log(visibleLowerTime, visibleUpperTime, Math.max(suggestedInterval, 10000))
  }

  var xValues = getTimesArray(Math.floor(visibleLowerTime), Math.ceil(visibleUpperTime), Math.floor(suggestedInterval));
  // var yValues = ['Time'];
  var yValues = xValues.map((x) => 0.5)

  var zValues = xValues.map((v, i) => getTimeColor(v, utc_offset));

  var textValues = xValues.map((v, i) => getTimeString(v, tz, utc_offset))


  var colorscaleValue = [
    [0, '#001f3f'],
    [1, '#C6FCFF']
  ];

  const plotlyData = [
    {
      type: 'scatter',
      name: 'time of day',
      x: xValues,
      y: zValues,
      text: textValues,
      hoverinfo: 'text',
      opacity: 1,
      line:{
        color:'darkgray'
      }
    },

    {
      type: 'scatter',
      x: xValues,
      y: yValues,

      hoverinfo: 'none',
      opacity: 0.1,
      line: {
        color:'lightgray'
      },
      showlegend: false
    }
  ]

  const shapes = []
  if (hoverTime !== null) {
    shapes.push({
      type: 'line',
      x0: hoverTime,
      x1: hoverTime,
      y0: -1,
      y1: 1,
      line: {
        width: 2,
        color: '#001f3f',
        dash: 'dot'
      }
    })

    if (hoverTimeEpsilon > 0) {
      shapes.push({
        type: 'rect',
        x0: hoverTime - hoverTimeEpsilon,
        x1: hoverTime + hoverTimeEpsilon,
        y0: -0.5,
        y1: 1,
        line: {
          color: 'rgb(60,60,60)',
          width: 1
        },
        fillcolor: 'rgba(60,60,60,0.5)'
      })
    }
  }



  return (
    <div className='time-controls-div'>
        <div hidden={minimumTime > Number.MIN_VALUE && maximumTime < Number.MAX_VALUE} className='time-controls-mask'>
          <small> Select an agent to load the timeline.</small>
        </div>
        <Plot
        data={plotlyData}
        useResizeHandler={true}
        onRelayout={relayout}
        onHover={hoverTimeline}
        onUnhover={unhoverTimeline}
        layout={{
          showlegend: true,
          hovermode: 'x unified',
          margin: { l: 70, r: 50, t: 5, b: 30 },
          xaxis: { range: [visibleLowerTime, visibleUpperTime], showgrid: false, zeroline: false, visible: true, tickvals: [visibleLowerTime, visibleUpperTime], ticktext: [getTimeString(visibleLowerTime, tz, utc_offset), getTimeString(visibleUpperTime, tz, utc_offset)] },
          yaxis: { range: [-0.1, 1.1], fixedrange: true, tickvals: [0, 1], ticktext: ['midnight', 'noon'], zeroline: false },
          shapes: shapes
        }}
        style={{ width: '100%', height: '100%' }}
      />
      </div>
  );
}
// }

export default TimeControls;
